import { apiProcess } from '../host/apiProcess'
import { createFetchParams } from '../host/createFetchParams'

export const apiGet = async (url) => {
    try {
        let fp = createFetchParams(url, 'GET', '')
        if (fp.error) 
            return {error: fp.error}
        
        const response = await fetch(fp.full_url, fp.options)
        if (!response.ok) 
            return {error: 'didnt respond with json, something is very broken'}
        
        const data = await apiProcess(response)
        
        console.log('🐦 apiGet', fp.full_url)
        return data

    } catch (error) {
        return {error: error}
    }
}