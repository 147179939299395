export const set_wasVisitedProfilePage_LS = () => {
    localStorage.setItem('wasVisitedProfilePage', 'true')
}

export const remove_wasVisitedProfilePage_LS = () => {
    localStorage.removeItem('wasVisitedProfilePage')
}

export const get_wasVisitedProfilePage_LS = () => {
    let wasVisitedProfilePage = localStorage.getItem('wasVisitedProfilePage')
    if (!wasVisitedProfilePage) 
        wasVisitedProfilePage = ''
    
    return wasVisitedProfilePage 
}