import { useEffect, useState } from 'react'
import { IonModal, useIonRouter, IonIcon, IonList, IonListHeader, IonThumbnail, IonItem, IonSkeletonText, IonLabel } from '@ionic/react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import FormSaveCard from './paymentCardForm/PaymentCardForm'
import { POST_customer_paymentMethod_create } from '../../profile/addPaymentCard_modal/POST_customer_paymentMethod_create'
import { POST_customer_paymentMethod_save } from '../../profile/addPaymentCard_modal/POST_customer_paymentMethod_save'
import useUserSession from '~/app_layout/_session/useUserSession'
import { POST_customer_paymentMethod_cancel } from '../../profile/addPaymentCard_modal/POST_customer_paymentMethod_cancel'
import css from './AddPaymentCard_modal.module.css'
import { close } from 'ionicons/icons'


const AddPaymentCard_modal = (props) => {
    const { loadUserInfo } = useUserSession()
    const router = useIonRouter()

    const [stripePromise, set_stripePromise] = useState(null)
    const [setupIntentId, set_setupIntentId] = useState('')
    const [options, set_options] = useState({
        clientSecret: '',
        appearance: {
            theme: 'stripe',
            labels: 'floating',
            variables: {
                colorText: '#000',
                fontWeightNormal: '700',
                iconColor: '#f7f7f7',
                iconCardCvcErrorColor: '#f7f7f7',
                gridRowSpacing: '8px',
                gridColumnSpacing: '2px'
            },
            rules: {
                '.Input': {
                  border: '3px solid #fff',
                  boxShadow: 'none',
                  backgroundColor: '#f7f7f7',
                  fontSize: '20px',
                  borderRadius: '10px'
                },
                '.Label': {
                    color: '#666',
                    display: 'none',
                    visiblity: 'hidden',
                    fontWeight: '500'
                },
                '.Input:focus': {
                    border: '3px solid #A1A1A0',
                    boxShadow: 'none',
                },
            },
        },
        fields: {
            billingDetails: 'never'
        } 
    })


    const createPaymentMethod = async () => {
        const resp = await POST_customer_paymentMethod_create()
        if (resp.error) {
            alert(resp.error)
            return
        }
        set_options(prev => ({...prev, clientSecret: resp.clientSecret }))
        set_setupIntentId(resp.setupIntentId)
    }


    const cancelPaymenthMethod = async (stripeSetupIntentId) => {
        const resp = await POST_customer_paymentMethod_cancel(stripeSetupIntentId)
        if (resp.error) {
            alert(resp.error)
            return
        }
        console.log(resp)
        // set_options({clientSecret: '' })
        set_options(prev => ({...prev, clientSecret: resp.clientSecret }))

        set_setupIntentId('')
    }


    const savePaymentMethod = async (stripeMethodId) => {
        const resp = await POST_customer_paymentMethod_save(stripeMethodId)
        if (resp.error) {
            alert(resp.error)
            return
        }
        console.log(resp)
        set_setupIntentId('')
        // set_options({clientSecret: ''})
        set_options(prev => ({...prev, clientSecret: resp.clientSecret }))


        const resp2 = await loadUserInfo()
        if (resp2.error) {
            alert(resp2.error)
            return
        }
        props.onAddCard()
    }


    const setupStripePromise = async () => {
        const stripePublishableKey = import.meta.env.VITE_REACT_STRIPE_PUBLISHABLE_KEY
        const resp = await loadStripe(stripePublishableKey)
        set_stripePromise(resp)
    }


    useEffect(() => {
        if (!setupIntentId) 
            return

        if (props.isOpen) 
            return
        
        cancelPaymenthMethod(setupIntentId)
        console.log('CanceLPaymenthMethod')
    }, [props.isOpen])

    useEffect(() => {
        if (!props.isOpen) 
            return

        setupStripePromise()
        console.log('setipStripePromise')
        createPaymentMethod()
        console.log('createPaymentMethod')
    }, [props.isOpen])

    
    
    /* useIonViewWillEnter(() => {
        createPaymentMethod()
    })

    useIonViewWillLeave(() => {
        console.log(setupIntentId)
        if (!setupIntentId) 
            return

        cancelPaymenthMethod(setupIntentId)
    }, [setupIntentId]) */

    return (
        <IonModal
            className={css.addCardModal}
            isOpen={props.isOpen}
            initialBreakpoint={0.9}
            breakpoints={[0, 0.6, 0.9]}
            backdropDismiss={false}
        >
            <div onClick={() => props.onCloseIconClick()} className={css.closeScanner}>
                <IonIcon icon={close} />
            </div>

            <div className={css.wrapper_stripe_elements} >
                {setupIntentId
                    ? <Elements stripe={stripePromise} options={options}>
                        <FormSaveCard onCreatedStripeMethodId={(stripeMethodId) => savePaymentMethod(stripeMethodId) } />
                    </Elements> 
                    : <IonList>
                        <IonListHeader>
                            <IonSkeletonText animated={true} style={{ width: '40%' }}></IonSkeletonText>
                        </IonListHeader>
                    </IonList>
                }
            </div> 
        </IonModal>
    )
}

export default AddPaymentCard_modal
