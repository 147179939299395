import { useState, useEffect } from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { checkmarkOutline } from 'ionicons/icons'
import avatar from '~/chat/_botAvatar/_assets/jarvis2.png'
import css from './ChatboxMessage.module.css'


const ChatboxMessage = (props) => {
    const [waitingText, set_waitingText] = useState('.')

    useEffect(() => {
        if (props.chatInfo.state == 'user')
            return

        let waitingInterval
        if (props.chatInfo.state == 'waiting') {
            waitingInterval = setInterval(() => {
                set_waitingText(prev => prev.length < 3
                    ? prev.concat('.')
                    : '.'
                )
            }, 500)
        }

        if (props.chatInfo.state == 'chatbot')
            set_waitingText('.')

        return () => {
            clearInterval(waitingInterval)
        }
    }, [props.chatInfo.state])

    return (
        <div className={css.header}>

            <div className={css.chatBot}>
                <div className={css.jarvis}>
                    <img src={avatar} alt='chat avatar' />
                </div>
                <div className={css.answer}>
                    <p>{props.chatInfo.state == 'chatbot' ? props.chatInfo.answer : waitingText}</p>
                </div>

                {props.chatInfo.state == 'chatbot' &&
                    <div className={css.chatButtons}>
                        <IonButton
                            routerLink='/cart' 
                            routerDirection='forward'
                            onClick={props.onUserEnd}
                        >
                            <IonIcon slot='icon-only' icon={checkmarkOutline} />
                        </IonButton>

                        <IonButton className='light' onClick={props.onAgain}>
                            Ask again
                        </IonButton>
                    </div>
                }
            </div>
        </div>
    )
}

export default ChatboxMessage