import { useRecoilState } from 'recoil'
import { cart_atom } from './cart.atom'
import { POST_cart_updateItem } from './POST_cart_updateItem'
import { hapticsImpactLight } from '~/zzz_native/impacts/hapticsImpacts'


const useProductPlusMinusButtons = () => {
    const [cart, setCart] = useRecoilState(cart_atom)

    const decreaseProductCount = async (productId) => {
        hapticsImpactLight()
        const resp = await POST_cart_updateItem(cart.id, productId, -1)
        if (resp.error) {
            alert(resp.error)
            return 
        }
        
        setCart(resp.cart)
    }

    const increaseProductCount = async (productId) => {
        hapticsImpactLight()
        const resp = await POST_cart_updateItem(cart.id, productId, 1)
        if (resp.error) {
            alert(resp.error)
            return 
        }

        setCart(resp.cart)
    }

    return {
        decreaseProductCount,
        increaseProductCount
    }
}

export default useProductPlusMinusButtons