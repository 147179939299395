import { IonRadioGroup, IonItem, IonRadio } from '@ionic/react'
import { toggleDevVisibility } from './toggleDevVisibility'


const TogglerDevCss = () => {
    return (
        <>
            <IonRadioGroup>
                <IonItem className='dev-lock'>
                    {/* <IonLabel>Show dev buttons</IonLabel> */}
                    <IonRadio
                        value='dev-lock' 
                        onClick={() => toggleDevVisibility(true)}
                    >
                        Show dev buttons
                    </IonRadio>
                </IonItem>
                <IonItem className='dev-lock'>
                    {/* <IonLabel>Hide dev buttons</IonLabel> */}
                    <IonRadio 
                        value='production' 
                        onClick={() => toggleDevVisibility(false)}
                    >
                        Hide dev buttons
                    </IonRadio>
                </IonItem>
            </IonRadioGroup>
        </>
    )
}

export default TogglerDevCss