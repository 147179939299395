import { IonButton, IonSpinner } from '@ionic/react'
import { useElements, useStripe, PaymentElement } from '@stripe/react-stripe-js'
import css from './PaymentCardForm.module.css'
import { useState } from 'react'


const FormCheckout = (props) => {
    const stripe = useStripe()
    const elements = useElements()
    const [processing, set_processing] = useState(false)

    const createStripeMethodId = async () => {
        console.log(elements);
        if (!stripe || !elements)
            return alert('Stripe or elements dont exist')
        
        set_processing(true)
        const resp = await stripe.confirmSetup({ elements, redirect: 'if_required' })
        if (resp.error) {
            console.log(resp.error)
            alert('Card declined. Try again or try another card.')
            return 
        }
           
        const stripeMethodId = resp.setupIntent.payment_method
        console.log(stripeMethodId);
        props.onCreatedStripeMethodId(stripeMethodId)
    }

    const isStripeReady = () => {
        if (!stripe)
            return ''

        return stripe 
    }

    return (
        <>
            <div className={css.wrapperPaymentElement}>
               <PaymentElement />
            </div>
            
            <IonButton
                className={css.saveCardButton} 
                onClick={createStripeMethodId} 
                disabled={!isStripeReady() || processing} //pr. v doc preco disabled={!stripe} -> https://stripe.com/docs/stripe-js/react#usestripe-hook
            >
                {processing ? <IonSpinner /> : 'Add Card'} 
            </IonButton>
        </>
    )
}

export default FormCheckout