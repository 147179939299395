import { useEffect, useRef } from 'react'
import css from './ChatMessages.module.css'
import useChat_messages from './useChat_messages'

const ChatMessages = () => {
    const scrollChatBottom_ref = useRef(null)
    
    const { loadMessages, chatMessages, setChatMessages } = useChat_messages()

    const updateMessages = async () => {
        const resp = await loadMessages()
        if (resp.error) {
            alert(resp.error)
            return
        }

        setChatMessages(resp.messages)
    }

    useEffect(() => {
        console.log(scrollChatBottom_ref)
        scrollChatBottom_ref.current.scrollIntoView({ behavior: 'smooth' })
    }, [chatMessages])

    useEffect(() => {
        updateMessages()
    }, [])
    
    return (
        <div className={css.messages}>
            {chatMessages.map((message, index) => ( 
                <div 
                    key={index} 
                    className={css[message.sender]}
                >
                    {message.text}
                </div>
            ))}
            <div ref={scrollChatBottom_ref} />
        </div>
    )
}

export default ChatMessages