import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { IonPage, IonContent, IonHeader, IonButton, IonSpinner } from '@ionic/react'
import { useRecoilState } from 'recoil'
import { userInfo_atom } from '~/app_layout/_session/userInfo_atom'
import { smsSentInfo_atom } from '~/verificationPhoneNumber/_layout/smsSentInfo_atom'
import css from './UserVerificationSmsCode.module.css'
import { POST_customer_verification_verifyCode } from './POST_customer_verification_verifyCode'
import { POST_customer_verification_sendSms } from '~/verificationPhoneNumber/_layout/POST_customer_verification_sendSms'
import Header from '~/app_layout/header/Header'


const VerificationSmsCode = () => {
    const history = useHistory()
    const [userInfo, set_userInfo] = useRecoilState(userInfo_atom)
    const [smsSentInfo, set_smsSentInfo] = useRecoilState(smsSentInfo_atom)

    const [timeLeft, setTimeLeft] = useState(15)
    const [code1, set_code1] = useState('')
    const [code2, set_code2] = useState('')
    const [code3, set_code3] = useState('')
    const [code4, set_code4] = useState('')
    const [processing_verify, set_processing_verify] = useState(false)
    const [processing_resend, set_processing_resend] = useState(false)

    const input1 = useRef(null)
    const input2 = useRef(null)
    const input3 = useRef(null)
    const input4 = useRef(null)
    

    // useIonViewDidEnter(() => {
    //     setTimeout(() => {
    //         input1.current.focus()
    //     }, 100)
    // })

    const onUserPaste = (event, inputRef) => {
        event.preventDefault()
        const code = event.clipboardData.getData('text/plain')
        if (code.length != 4 || isNaN(code)) {
            return
        }

        set_code1(code[0])
        set_code2(code[1])
        set_code3(code[2])
        set_code4(code[3])

        inputRef.current.blur()
    }

    const onUseBox = (code, inputRef) => {
        if (code.length != 4)
            return

        set_code1(code[0])
        set_code2(code[1])
        set_code3(code[2])
        set_code4(code[3])

        inputRef.current.blur()
    }

    const onChangeField = (event, onSetCode, inputRef, nextInputRef = null) => {
        const code = event.target.value

        if (isNaN(code) || !code) {
            onSetCode('')
            return
        }

        if (code.length > 1) {
            onUseBox(code, inputRef)
            return
        }

        if (code.length != 1) {
            onSetCode('')
            return
        }

        onSetCode(code)
        nextInputRef
            ? nextInputRef.current.focus()
            : inputRef.current.blur()
    }

    const submitVerification = async () => {
        let fullcode = code1 + code2 + code3 + code4

        set_processing_verify(true)
        let resp = await POST_customer_verification_verifyCode(smsSentInfo.verificationId, fullcode, null)
        if (resp.error) {
            alert(resp.error)
            set_code1('')
            set_code2('')
            set_code3('')
            set_code4('')
            set_processing_verify(false)
            return
        }
        set_processing_verify(false)

        alert('You have been successfully verified')
        set_userInfo(prev => {return {...prev, verified: true}})
        set_smsSentInfo(prev => { 
            return {
                ...prev, 
                verificationId: '',
            }
        })
        history.push('/home')          
    }

    const submitResendSms = async () => {
        set_processing_resend(true)
        const resp = await POST_customer_verification_sendSms(smsSentInfo.phoneNumber)
        if (resp.error) {
             alert('Failed to send SMS', resp.error)
             set_processing_resend(false)
             return
        }
        set_processing_resend(false)

        set_smsSentInfo(prev => { 
            return {
                ...prev, 
                verificationId: resp.verificationId 
            }
        })
        alert('A verification code has been sent to your phone number. It usually comes within 10 seconds.')
        setTimeLeft(15)
    }
    

    useEffect(() => {
        // TIMER 
        if (timeLeft == 0) {
            console.log("TIME LEFT IS 0");
            setTimeLeft(null)
        }
     
        if (!timeLeft) 
            return
     
        const intervalId = setInterval(() => {
           setTimeLeft(prev => prev - 1)
        }, 1000)
     
        return () => clearInterval(intervalId);
    }, [timeLeft])
    

    return (
        <IonPage>
            <IonHeader>
                <Header 
                    goBackButton={true}
                    defaultHref='/verification-phone-number'
                    //showNavigationIcon={true}
                />
            </IonHeader>   

            <IonContent className={css.content} >
                <div className={css.header}>
                    <h1>
                        Enter the code
                    </h1>
                    <div>
                        We have sent a code to <span>{smsSentInfo.phoneNumber}</span>
                    </div>
                </div>

                <div className={css.fields_wrapper}>
                    <input
                        ref={input1} 
                        className={css.basicField}
                        type='number'
                        value={code1}
                        onChange={(e) => onChangeField(e, set_code1, input1, input2)}
                        onPaste={(e) => onUserPaste(e, input1)}
                        inputMode='numeric'
                    />
                    <input
                        ref={input2}
                        className={css.basicField}
                        type='number'
                        value={code2}
                        onChange={(e) => onChangeField(e, set_code2, input2, input3)}
                        onPaste={(e) => onUserPaste(e, input2)}
                        inputMode='numeric'
                    />
                    <input
                        ref={input3}
                        className={css.basicField}
                        type='number'
                        value={code3}
                        onChange={(e) => onChangeField(e, set_code3, input3, input4)}
                        onPaste={(e) => onUserPaste(e, input3)}
                        inputMode='numeric'
                    />
                    <input
                        ref={input4}
                        className={css.basicField}
                        type='number'
                        value={code4}
                        onChange={(e) => onChangeField(e, set_code4, input4)}
                        onPaste={(e) => onUserPaste(e, input4)}
                        inputMode='numeric'
                    />
                </div>

                <div className={css.resendText}>
                    {timeLeft &&
                        <>
                            Resend code in&nbsp;  
                            {timeLeft}
                        </> 
                    } 
                </div>

                <div className={css.buttons_wrapper}>
                    <IonButton 
                        className={css.verifyButton} 
                        onClick={submitVerification}
                        disabled={processing_verify}
                    >
                        {processing_verify 
                            ? <IonSpinner />
                            : 'VERIFY'
                        }
                    </IonButton>

                    {!timeLeft &&
                        <IonButton 
                            className={css.resendSmsButton} 
                            onClick={submitResendSms}
                            disabled={processing_resend}
                        >
                            {processing_resend 
                                ? <IonSpinner />
                                : 'RESEND'
                            }
                        </IonButton>
                    }
                </div>

                <div className='dev ml-4 w-max py-3 mt-6 px-4' onClick={() => history.push('/home') }>skip</div>

            </IonContent>
        </IonPage>
    )
}

export default VerificationSmsCode