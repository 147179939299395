import { IonIcon } from '@ionic/react'
import { chevronForward } from 'ionicons/icons'
import { useHistory } from 'react-router'
import { set_wasVisitedProfilePage_LS } from './wasVisitedProfilePage_LS.util'
import css from './UserProfilePrompt.module.css'


const UserProfilePrompt = () => {
    const history = useHistory()

    const goToProfile = () => {
        history.push('/profile')
        set_wasVisitedProfilePage_LS()
    }

    return (
        <div onClick={goToProfile} className={css.wrapper}>
            <div>
                Complete your profile
            </div>
            <IonIcon icon={chevronForward}/>
        </div>
    )
}

export default UserProfilePrompt