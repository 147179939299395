import { IonCard, IonIcon } from '@ionic/react'
import { add, ellipse, close } from 'ionicons/icons'
import css from './PaymentMethods.module.css'
import VisaLogo from './_assets/VisaLogo.png'


const PaymentMethods = (props) => {
    return (
        <IonCard className={css.wrapper}>
            <div className={css.header}>
                <h1>
                    Payment Methods
                </h1>
                <div 
                    onClick={() => props.paymentMethod
                        ? alert('Only one payment method is allowed') 
                        : props.onAddCardClick()
                    } 
                    className={css.add_card} 
                    disabled={props.paymentMethod}
                >
                    <IonIcon icon={add} />
                    Add Card
                </div>
            </div>

            <div className={css.cards}>
                {props.paymentMethod 
                    ? <div className={css.card}>
                        <div className={css.logo}>
                            <img src={VisaLogo} />
                        </div>
                        <div className={css.lastNumbersOfCard}>
                            <IonIcon icon={ellipse} />
                            <IonIcon icon={ellipse} />
                            <IonIcon icon={ellipse} />
                            <IonIcon icon={ellipse} />
                            <span>
                                {props.paymentMethod.card.last4}
                            </span>
                        </div>
                        <div
                            onClick={props.onRemoveCardIconClick}
                            className={css.removeIcon}
                        >
                            <IonIcon icon={close} />
                        </div>
                    </div>
                    : <div className={css.text}>
                        No payment method has been added yet
                    </div>
                }
            </div>   
        </IonCard>
    )
}

export default PaymentMethods