import { atom } from "recoil"

export const userInfo_atom = atom({
    key: 'userInfo_atom',
    default: {
        balance: null,
        name: '',
        phoneNumber: '',
        photo: null,
        verified: false,
        paymentMethod: null,
    }
})