import css from './CartPaymentInfo.module.css'
import VisaLogo from '~/profile/paymentMethods/_assets/VisaLogo.png'
import { ellipse, add } from 'ionicons/icons'
import { IonIcon } from '@ionic/react'
import { formatPrice } from '~/zzz_formatting/numbers/fixToTwoDecimal'


const CartPaymentInfo = (props) => {
    return (
        <div className={css.paymentStuff}>
            {props.paymentMethod
                ? <div className={css.paymentMethod}>
                    <div className={css.logo}>
                        <img src={VisaLogo} />
                    </div>
                    <div className={css.lastNumbersOfCard}>
                        <IonIcon icon={ellipse} />
                        <IonIcon icon={ellipse} />
                        <IonIcon icon={ellipse} />
                        <IonIcon icon={ellipse} />
                        <span>
                            {props.paymentMethod.card.last4}
                        </span>
                    </div>
                </div>
                : <div 
                    className={css.add_card} 
                    onClick={() => props.onAddCardClick()} 
                >
                    <IonIcon icon={add} />
                    Add Card
                </div>
            }
            
            <div className={css.totalPrice}>
                <span>   
                    €
                </span>
                {formatPrice(props.totalPrice)}
            </div>
        </div>
    )
}

export default CartPaymentInfo