import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { IonPage, IonHeader, IonContent, IonButton, IonIcon, IonCard, IonCardHeader, useIonViewWillEnter, useIonViewWillLeave, IonFooter, useIonViewDidEnter } from '@ionic/react'
import { useRecoilValue } from 'recoil'
import { cart_atom } from '~/cart/_layout/cart.atom'
import { userInfo_atom } from '~/app_layout/_session/userInfo_atom'
import Header from '~/app_layout/header/Header'
//import PaymentMethods from '../paymentMethods/PaymentMethods'
//import LoyaltyCards from '../loyaltyCards/LoyaltyCards'
import css from './HomePage.module.css'
import useUserSession from '~/app_layout/_session/useUserSession'
import ErrorAlert from '~/home/errorAlert/ErrorAlert'
import { removeJwt_LS } from '~/app_layout/_session/jwt_LS.util'
import ImgYemePanorama from './_assets/yeme_panorama.jpg'
import useCartFinish from '~/cartFinish/_layout/useCartFinish'
import CompleteYourProfile from '../userProfilePrompt/UserProfilePrompt'
import { get_wasVisitedProfilePage_LS, remove_wasVisitedProfilePage_LS } from '../userProfilePrompt/wasVisitedProfilePage_LS.util'
import { cartOutline } from 'ionicons/icons'
import { formatPrice } from '~/zzz_formatting/numbers/fixToTwoDecimal'
import RecentPurchases from '../recentPurchases/RecentPurchases'


const HomePage = () => {
    const cart = useRecoilValue(cart_atom)
    const history = useHistory()
    
    const { createGuestAccount, loadUserInfo, hasJwtSession, isLoaded } = useUserSession()
    const { restartProcessOfShopping } = useCartFinish()
    const [showErrorAlert, set_showErrorAlert] = useState(false)
    const userInfo = useRecoilValue(userInfo_atom)
    //const [showRecentPurchasesModal, set_showRecentPurchasesModal] = useState(true)

    const ensureUserSession = async () => {
        if (hasJwtSession()) {
            const resp = await loadUserInfo()
            if (resp.error) {
                set_showErrorAlert(true)
                return
            }
        } else {
            const resp = await createGuestAccount()
            if (resp.error) {
                alert(resp.error)
                return
            }
            const resp2 = await loadUserInfo()
            if (resp2.error) {
                set_showErrorAlert(true)
                return
            } 
        }
    }
    
    // pri kazdom vstupe na homePage sa uistime ze mame userSession
    useEffect(() => {
        console.log('vstup na homepage')
        ensureUserSession()
    }, [])

    useIonViewWillEnter(() => {
        console.log(cart);
    }, [cart])
    
    useIonViewWillEnter(() => {
        //set_showRecentPurchasesModal(true)
    })

    useIonViewWillLeave(() => {
        //set_showRecentPurchasesModal(false)
    })

    const onErrorAlertSubmit = () => {
        removeJwt_LS()
        remove_wasVisitedProfilePage_LS()
        window.location.reload()
    }
    
    return (
        <IonPage>
            <IonHeader>
                <Header
                    showUserPhoto={true}
                    showRingerIcon={true}
                    isLoaded={isLoaded} 
                />
            </IonHeader>

            <IonContent className={css.content} >
                <div className={css.container}>

                    {!get_wasVisitedProfilePage_LS() &&
                        <CompleteYourProfile />
                    }

                   <IonCard className={css.intro_card}>
                        <div className={css.img_wrapper}>
                            <img src={ImgYemePanorama} alt="Yeme Panorama City" />
                        </div>
                        {cart.id &&
                            <div className={css.cart_panel}>
                                <div className={css.cartIcon}>
                                    <IonIcon icon={cartOutline} />
                                </div>
                                <div className={css.totalPrice}>
                                    <span>   
                                        €
                                    </span>
                                    {formatPrice(cart.total_price)}
                                </div>
                            </div>
                        } 
                        <IonCardHeader>
                            <h2>Yeme, Panorama City</h2>
                            <h3>
                                {cart.id 
                                    ? 'You are currently shopping in'
                                    : 'It seems like you are in'
                                }
                            </h3>
                        </IonCardHeader>
                    </IonCard>

                    {/* <div className={css.profile}>
                        <div className={css.userAvatar}>
                            <IonImg src={guestAvatar} alt='customer profile photo' />
                        </div>
                        <h1>
                           Welcome, {userInfo.name ? userInfo.name : 'Guest'} 
                        </h1>
                        <IonLabel className={css.editButton} onClick={() => history.push('/profile')}>
                            edit
                            <IonIcon 
                                className={css.personIcon} 
                                icon={pencilOutline}
                            />
                        </IonLabel>
                    </div> */}

                    {/* <IonCard class={css.payInfo}>
                        <IonCardContent>
                            <div className={css.cubeBalance}>
                                {userInfo.balance &&
                                    <>
                                        <span className={css.currency}>DevX</span>
                                        <span>{userInfo.balance}</span>
                                    </>
                                }
                            </div> 

                            <PaymentMethods />
                        </IonCardContent>
                    </IonCard> */}
                    
                    {/* <LoyaltyCards /> */}
                     
                    {cart.id
                        ? <>
                            <div className={css.continue_shopping} onClick={() => history.push('/cart')} >
                                <div>
                                    <div>
                                        Continue Shopping
                                    </div>
                                </div>
                            </div>

                            <div onClick={restartProcessOfShopping} className={css.exitStore}>
                                Exit Store
                            </div>
                        </> 
                        : <IonButton className={css.begin_shopping} routerLink='/cart' >
                            Begin Shopping
                        </IonButton>
                    }

                    {/* <div className={css.welcomeLead}>
                        <div className={css.leadInfo}>
                            <p>
                                It seems like you want to shop in OdNašich - Devín, right?
                            </p>
                            <div className={css.jarvis}>
                                <img src={avatar} alt='chat avatar' />
                            </div>
                        </div>
                        <div className={css.leadButtons}>
                            <IonButton
                                routerLink='/cart' 
                                routerDirection='forward'
                            >
                                <IonIcon slot='icon-only' icon={checkmarkOutline} />
                            </IonButton>
                            <IonButton className='light'>
                                Other
                            </IonButton>
                        </div>
                    </div> */}         
                </div>


              <ErrorAlert 
                    isOpen={showErrorAlert}
                    onErrorAlertSubmit={onErrorAlertSubmit}
                    onErrorAlertCancel={() => set_showErrorAlert(false)}
                />
            </IonContent>

            <IonFooter>
                <div className={css.footer_container}>
                    <RecentPurchases
                        cart_id={cart.id}
                        balance={userInfo.balance}
                        //isOpen={showRecentPurchasesModal}
                        //onDidDismiss={() => set_showRecentPurchasesModal(false)}
                    />
                </div>
            </IonFooter>
        </IonPage>
    )
}

export default HomePage