import { IonContent, IonHeader, IonItem, IonTitle, IonToolbar, IonMenu, IonMenuToggle, IonButton } from '@ionic/react'
import { useHistory, useParams} from 'react-router'
import TogglerDevCss from '~/zzz_dev/toggler/TogglerDevCss'
import { hapticsImpactLight, hapticsImpactMedium, hapticsImpactHeavy } from '~/zzz_native/impacts/hapticsImpacts'
import { hapticsVibrate } from '~/zzz_native/impacts/hapticsVibrate'
import cink_sound from '~/payment/_layout/_assets/shortSound_1.mp3'


const Menu = () => {
    const urlCartId = useParams()
    const history = useHistory()
    
    const paths = [
        { name: 'Welcome',           url: '/welcome'                   },
        { name: 'Enter your number', url: '/verification-phone-number' },
        { name: 'Enter your code',   url: '/verification-sms-code'     },
        { name: 'Home',              url: '/home'                      },
        { name: 'Pay with Cube',     url: `/cart/${urlCartId}/payment` },
        { name: 'Add loyalty card',  url: '/add-loyalty-card'          },
        { name: 'Cart',              url: '/cart'                      },
        { name: 'Product',           url: '/product/2'                 },
        { name: 'Chat',              url: '/chat'                      },
        { name: 'Cart Finish',       url: '/cart-finish'               },
        { name: 'Profile',           url: '/profile'                   },
        { name: 'Verfication',       url: '/verification'              },
    ]

    const playCinkSound = () => {
        const cinkSound = new Audio(cink_sound)
        cinkSound.play()
    }
    
    const dev_reset = () => {
        localStorage.clear() 
        history.replace('/')
        window.location.reload()
    }

    return (
        <IonMenu maxEdgeStart={-1} contentId='main'>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        MENU
                    </IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonItem>
                    {import.meta.env.VITE_REACT_API_HOST}
                </IonItem>

                <TogglerDevCss />

                <IonButton className='dev-lock' expand='block' onClick={dev_reset}>
                    dev_RESET
                </IonButton>
                
                {paths.map((item, index) => (
                    <IonMenuToggle key={index}>
                        <IonItem className='dev-lock' routerLink={item.url}>
                            {item.name}
                        </IonItem>
                    </IonMenuToggle>
                ))}

                <IonButton className='dev-lock' expand='block' onClick={playCinkSound}>
                    play_audio_1
                </IonButton>

                <IonButton className='dev-lock' expand='block' onClick={hapticsImpactLight}>
                    haptics_light
                </IonButton>

                <IonButton className='dev-lock' expand='block' onClick={hapticsImpactMedium}>
                    haptics_medium
                </IonButton>

                <IonButton className='dev-lock' expand='block' onClick={hapticsImpactHeavy}>
                    haptics_heavy
                </IonButton>

                <IonButton className='dev-lock' expand='block' onClick={hapticsVibrate}>
                    haptics_vibrate
                </IonButton>
            </IonContent>
        </IonMenu>     
    )
}

export default Menu