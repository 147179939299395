import { IonItemSliding, IonItem, IonItemOptions, IonItemOption, IonLabel, IonIcon } from '@ionic/react'
import { caretForwardOutline } from 'ionicons/icons'
import { useRef } from 'react'

import css from './IonSwipeButton.module.css'

const IonSwipeButton = (props) => {
    const sliderRef = useRef(null)

    const onUserSwipe = async () => {
        props.onSwipe()
        const position = await sliderRef.current.getOpenAmount()
        if (position == 222) {
            sliderRef.current.closeOpened()
        }

        setTimeout(() => {
            sliderRef.current.closeOpened()
        }, 500)
    }

    const getSliderPosition = async () => {
        const position = await sliderRef.current.getSlidingRatio()
        const absPosition = Math.abs(position)
        if (absPosition >= 1) {
            onUserSwipe()
        }
    }


    return (
        <div 
            className={`${css.wrapper} ${props.disabled && css.disabled}`}
            style={{borderBottomColor: props.maskBorder}}
        >
            <IonItem
                className={css.backgroundItem}
                style={{background: props.sliderBackground}}
            >
                {props.showLabel &&
                    <IonLabel className={css.backgroundLabel}>
                        <span style={{fontSize: props.fontSize}}>{props.label}</span>
                        {props.showLabelCarrets && <>
                            <IonIcon className={`${css.labelIcon} opacity-90`} icon={caretForwardOutline} />
                            <IonIcon className={`${css.labelIcon} opacity-70`} icon={caretForwardOutline} />
                            <IonIcon className={`${css.labelIcon} opacity-40`} icon={caretForwardOutline} />
                        </>}
                    </IonLabel>
                }
            </IonItem>
            <IonItemSliding
                ref={sliderRef}
                className={css.slidingWrapper}
                onIonDrag={getSliderPosition}
            >
                <IonItemOptions 
                    className={css.options}
                    side='start'
                >
                    <IonItemOption className={css.option} expandable></IonItemOption>
                </IonItemOptions>
                
{/*                 <div className='bg-transparent border border-green-900 w-[500px] overflow-hidden relative'>
 */}                <IonItem
                    lines='none'
                    className={css.parentItem}
                    shadowCustomCss='.input-wrapper .item-native .item-inner{border:none !important;}'
                >
                    <IonLabel className={css.slideLabel}>
                        {props.carretImage
                            ? <img
                                className={css.caret}
                                style={{
                                    background: props.carretBackground,
                                    borderColor: props.carretBorder
                                }}
                                src={props.userPhoto ? props.userPhoto : props.carretImage}
                                alt='swipe carret image'
                            />
                            : <div
                                className={css.emptyCarret}
                                style={{
                                    background: props.carretBackground,
                                    borderColor: props.carretBorder
                                }}
                            ></div>
                        }
                    </IonLabel>
                   
                    {/* <div className={css.displayedLayerIfSwiping}>
                    </div> */}
                
                </IonItem>
{/*                 </div>
 */}
              {/*   <div className='absolute top-[13px] bottom-0 h-[58px] w-20 z-[9999] bg-red-100'>
                    <div className='absolute top-0 right-0 bottom-0 bg-slate-400 h-full w-4'>

                    </div>

                </div> */}

                {/* 
                    Pre IonItem (css.parentItem) neviem v shadow part naštýlovať element, ktorý má spodný border,
                    t.j. neviem nastaviť border: none;
                    Takto maskujem border, takže treba props.maskBorder nastaviť na farbu pozadia, v ktorom je swipe použitý, aby zmizol spodný border
                */}
                <div
                    className={css.borderMask}
                    style={{backgroundColor: props.maskBorder}}
                ></div>
            </IonItemSliding>
        </div>
    )
}

IonSwipeButton.defaultProps = {
    onSwipe: () => {},
    label: 'Slide to continue',
    sliderBackground: 'blue',
    carretImage: null,
    carretBackground: 'white',
    carretBorder: 'black',
    fontSize: '16px',
    maskBorder: 'transparent',
    showLabelCarrets: true,
    showLabel: true,
    disabled: false
}

export default IonSwipeButton