export const apiProcess = async (response) => {

    let text = await response.text()

    let json
    try {
        json = JSON.parse(text)
    }
    catch (e) {
        return {error: 'response doesn´t contain json'}
    }
    return json
}