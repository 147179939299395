import { IonButtons, IonBackButton, IonMenuButton, IonToolbar, IonIcon, IonBadge, IonImg, IonSpinner } from '@ionic/react'
import { useHistory } from 'react-router'
import { useRecoilValue } from 'recoil'
import css from './Header.module.css'
import { personCircleOutline, arrowBackOutline, notificationsOutline } from 'ionicons/icons'
import { TbNavigationFilled } from 'react-icons/tb'
import VerifiedCheckMark from './_assets/chceck_mark.png'
import { userInfo_atom } from '../_session/userInfo_atom'
import jarvisIcon from '~/chat/_botAvatar/_assets/jarvis2.png'
import { getFirstNameOrGuest } from '~/zzz_formatting/fullName/getFirstNameOrGuest'


const Header = (props) => {
    const userInfo = useRecoilValue(userInfo_atom)
    const history = useHistory()

    return (
        <>
            <div className={css.navigation_wrapper}>            
                <IonToolbar>
                    <IonButtons slot='start' className={css.wrapperButtonsStart} >
                        {props.goBackButton &&     
                            <IonBackButton
                                text='' 
                                defaultHref={props.defaultHref} 
                                icon={arrowBackOutline}
                                disabled={props.backButtonDisabled}
                            />
                        }

                        {props.showMenuButton &&
                            <IonMenuButton />
                        }

                        {props.navigateStaticButton &&
                            <div className={`${css.iconWrapperStaticButton}`}>
                                <IonIcon 
                                    className={css.navigateStaticButton} 
                                    onClick={() => props.onStaticButtonClick()} 
                                    icon={arrowBackOutline}
                                /> 
                            </div> 
                        }

                        {props.showUserPhoto &&
                            <div className={css.iconWrapper}>
                                {!userInfo.photo
                                    ? <IonIcon
                                        className={css.personIcon} 
                                        src={personCircleOutline}
                                        onClick={() => history.push('/profile')}  
                                    />
                                    : <div onClick={() => history.push('/profile')} className={css.wrapper_userPhoto}>
                                        <img className={css.userPhoto} src={userInfo.photo} alt="user photo" />
                                    </div>
                                }

                                {userInfo.verified &&
                                    <img src={VerifiedCheckMark} />
                                }
                                <div className={css.userName}>
                                    {props.isLoaded
                                        ? 'Hi ' + getFirstNameOrGuest(userInfo.name)
                                        : ''
                                    }
                                </div>
                            </div>
                        }
                    </IonButtons>
                    <div className='dev-lock inline-block ml-24 !opacity-10 z-[999] px-4' onClick={() => document.querySelector('ion-menu').open()}>m</div>

        
                    {props.showLocation && 
                        <div className={css.location}>
                            {props.showNavigationIcon &&
                                <TbNavigationFilled className={css.iconMenu} />
                            }
                            <IonBadge>
                                {props.title 
                                    ? props.title 
                                    : 'Od našich - Hradná, Devín'
                                }  
                            </IonBadge>
                        </div>
                    }

                    {props.showRingerIcon &&
                        <IonButtons slot='end' className={`fake ${css.iconWrapper}`}>
                            <IonIcon 
                                className={css.ringerIcon} 
                                icon={notificationsOutline}
                            />
                            <div className={css.mark}>
                                <div />
                            </div>
                        </IonButtons>
                    }

                    {/* {props.showJarvisIcon &&
                        <div 
                            slot='end'
                            className={`
                                ${css.iconWrapper}
                                ${props.activeJarvis && css.active}
                            `}
                            onClick={props.onIconClick}
                        >
                            <IonImg className={css.jarvisIcon} src={jarvisIcon} />
                        </div>
                    } */}

                    {props.showLocationText &&
                        <div className={css.locationText} slot='end'>
                            Yeme, Panorama City
                        </div>
                    }                    
                </IonToolbar>
            </div>
        </>   
    )
}

export default Header