import { useRef } from 'react'
import CartProductsItem from '~/cart/products/item/CartProductsItem'
import css from './CartProducts.module.css'

const CartProducts = (props) => {
    const scrollProductsListBottom_ref = useRef(null)

    return (
        !props.cart_items.length
            ? <div className={css.emptyCart}>
                There are no products in your cart yet!
            </div>

            : <div className={css.list}>   
                {props.cart_items.map(product =>
                    <CartProductsItem
                        key={product.product_id}
                        product={product}
                        increaseProductCount={props.increaseProductCount} 
                        decreaseProductCount={props.decreaseProductCount}
                        isSummary={props.isSummary}
                        onProductItemClick={() => props.onProductItemClick(product)}
                        deleteProduct={props.deleteProduct}

                        isScannerModalOpen={props.isScannerModalOpen}
                        totalPrice={props.totalPrice}
                        cartItems={props.cart_items}
                        scrollProductsListBottom_ref={scrollProductsListBottom_ref}
                        contentRef={props.contentRef}
                        
                        lastAddedProduct={props.lastAddedProduct}
                        lastScanProductId={props.lastScanProductId}
                        lastScanT={props.lastScanT}
                    />
                )}
                <div ref={scrollProductsListBottom_ref} />
            </div>   
    )
}


export default CartProducts