import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import css from './ChatOptionsSlider.module.css' 
import useChat_chatOptions from './useChat_chatOptions'


const ChatOptionsSlider = (props) => {
    const [chatOptions, setChatOptions] = useState([])

    const { getChatOptions, ask_andAddReply } = useChat_chatOptions()

    const updateChatOptions = async () => {
        const resp = await getChatOptions()
        if (resp.error) {
            alert(resp.error)
            return
        }

        setChatOptions(resp.chatOptions)
    }

    useEffect(() => {
        updateChatOptions()
    }, [])

    return (
        <>
            <div className={css.slider}>
                {chatOptions.map((possibleQuestion, index) => (
                    <Link
                        key={index}
                        onClick={() => ask_andAddReply(possibleQuestion)}
                        to={props.isInCart ? '/chat' : '#'}
                    >
                        <div>
                            {possibleQuestion}
                        </div>
                    </Link>
                ))}
            </div>
            <div className={props.isInCart ? css.centerFadedGradient : css.fadedGradient} />
        </>
    )
}

export default ChatOptionsSlider