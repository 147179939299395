import { useEffect, useState } from 'react'
import { IonContent, IonPage, IonHeader, IonButton, IonIcon, useIonViewWillEnter } from '@ionic/react'
import { useRecoilValue } from 'recoil'
import css from './CartFinishPage.module.css'
import { userInfo_atom } from '~/app_layout/_session/userInfo_atom'
import { preventNavigateSwipeBack } from '~/zzz_react/preventNavigateSwipeBack/preventNavigateSwipeBack'
import { ellipse, informationCircleOutline } from 'ionicons/icons'
import Header from '~/app_layout/header/Header'
import useCartFinish from './useCartFinish'
import { getFirstNameOrGuest } from '~/zzz_formatting/fullName/getFirstNameOrGuest'
import VisaLogo from '~/profile/paymentMethods/_assets/VisaLogo.png'
import SummaryCartProducts from '../summaryProducts/SummaryCartProducts'


const CartFinishPage = () => {
    const userInfo = useRecoilValue(userInfo_atom)
    const { restartProcessOfShopping, getReceiptCode, cart } = useCartFinish()

    const [cartState, set_cartState ] = useState('')
    const [totalPrice, set_totalPrice] = useState('')
    const [receiptCode, set_receiptCode ] = useState('')
    const [cartItems, set_cartItems ] = useState([])

    useEffect(() => {
        preventNavigateSwipeBack('cartFinishPage')
    }, [])

    useIonViewWillEnter(() => {
        set_cartState(cart.state) 
        set_totalPrice(cart.total_price)
        set_receiptCode(getReceiptCode())
        set_cartItems(cart.cart_items)
    })

    
    return (
        <IonPage id='cartFinishPage'>
            <IonHeader>
                <Header
                    navigateStaticButton={true}
                    onStaticButtonClick={() => restartProcessOfShopping()}
                    showLocationText={true}
                />
            </IonHeader>
   
            <IonContent class={css.content}>
                <div className={css.main}>

                    {cartState == 'failed' &&
                        <h1 className={css.failureNotice}>
                            Payment failed!
                        </h1>
                    }
           
                    {cartState == 'paid' &&
                        <>
                            <h1  className={css.greetingText}>
                                Enjoy,&nbsp; 
                                {getFirstNameOrGuest(userInfo.name)}
                                &nbsp;<span>❤️</span>
                            </h1> 

                            <div className={css.summaryBox}>
                                <div className={css.header}>
                                    <h4>
                                        Your receipt
                                    </h4>
                                    <div className={css.receiptCode}>
                                        {receiptCode}
                                    </div>
                                </div>

                                <SummaryCartProducts cart_items={cartItems} />

                                <div className={css.gap} />

                                <div className={css.cart_panel}>
                                    <div className={css.paymentMethod}>
                                        <div className={css.logo}>
                                            <img src={VisaLogo} />
                                        </div>
                                        <div className={css.lastNumbersOfCard}>
                                            <IonIcon icon={ellipse} />
                                            <IonIcon icon={ellipse} />
                                            <IonIcon icon={ellipse} />
                                            <IonIcon icon={ellipse} />
                                            <span>
                                                {userInfo.paymentMethod 
                                                    ? userInfo.paymentMethod.card.last4
                                                    : ''
                                                }
                                            </span>
                                        </div>
                                    </div>

                                    <div className={css.totalPrice}>
                                        <span>   
                                            €
                                        </span>
                                        {totalPrice}
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                       
                    <div className={css.goHomeBtn} >
                        <IonButton onClick={restartProcessOfShopping} >
                            Go Back to Home     
                        </IonButton>
                        
                        <div onClick={() => alert('You can call the phone number 0944 022 380')} className={css.helpText}>
                            <IonIcon icon={informationCircleOutline} />
                            Get Help With Your Order
                        </div>
                    </div>
                    
                </div>
            </IonContent>
        </IonPage>
    )
}

export default CartFinishPage
