import { atom } from "recoil"

export const cart_atom = atom({
    key: 'cart_atom',
    default: {
        cart_items: [],
        total_price: 0,
        id: null,
    }
})






