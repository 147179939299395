import { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router'
import { IonContent, IonPage, IonHeader, IonFooter, useIonViewDidLeave, useIonRouter} from '@ionic/react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { cart_atom } from '~/cart/_layout/cart.atom'
import { lastAddedProduct_atom } from '~/product/_layout/product.atom'
import { userInfo_atom } from '~/app_layout/_session/userInfo_atom'
import { showAddCardModalOpen_atom } from '~/profile/addPaymentCard_modal/showAddCardModalOpen_atom'

import { preventNavigateSwipeBack } from '~/zzz_react/preventNavigateSwipeBack/preventNavigateSwipeBack'

import css from './CartPage.module.css'
import Header from '~/app_layout/header/Header'
import CartProducts from '../products/CartProducts'
import Chatbox from '../chatbox/Chatbox'
import IonSwipeButton from '~/zzz_react/ionSwipe/IonSwipeButton'
import ScanBarcodeOrUseNfc from '../scan/ScanBarcodeOrUseNfc'
import CartPaymentInfo from '../cartPaymentInfo/CartPaymentInfo'
import ChatToggleButton from '../chatToggleButton/ChatToggleButton'
import useProductPlusMinusButtons from './useProductPlusMinusButtons'
import useCart_UpdateItems from './useCart_UpdateItems'

import BarcodeScanner_modal from '../barcodeScanner_modal/BarcodeScanner_modal'
import ProductDetail_modal from '../productDetail_modal/ProductDetail_modal'
import AddPaymentCard_modal from '~/profile/addPaymentCard_modal/AddPaymentCard_modal'

import guest from '~/profile/_layout/_assets/guest.png'



const CartPage = () => {
    const router = useIonRouter()
    const history = useHistory()

    const [chatInfo, set_chatInfo] = useState({
        show: false,
        state: '',
        question: '',
        answer: ''
    })
    const [isScannerModalOpen, set_isScannerModalOpen] = useState(false)
    const [isProductDetailModalOpen, set_isProductDetailModalOpen] = useState(false)
    const [selectedProduct, set_selectedProduct] = useState({})

    const [lastScanProductId, set_lastScanProductId] = useState(null)
    const [lastScanT, set_lastScanT] = useState(0)

    const [showAddCardModalOpen, set_showAddCardModalOpen] = useRecoilState(showAddCardModalOpen_atom)
    const userInfo = useRecoilValue(userInfo_atom)
    const lastAddedProduct = useRecoilValue(lastAddedProduct_atom)
    const [cart, set_cart] = useRecoilState(cart_atom)
    const contentRef = useRef()
    const chatRef = useRef()
    const { increaseProductCount, decreaseProductCount } = useProductPlusMinusButtons()
    const { addProductToCart, create_cart_if_not_exist, deleteProduct } = useCart_UpdateItems()

    const dev_addProductToCart = () => {
        addProductToCart(3, 1)
    }

    useEffect(() => {
        //preventNavigateSwipeBack('cartPage')

        console.log('WARN !!!: Cart ID was loaded. If you refreshed paymentPage and go back to CartPage, cart.id(recoil) is probably not equal urlCartId from url')
    },[cart.id])

    useEffect(() => {
        preventNavigateSwipeBack('cartPage')
    }, [])

    useIonViewDidLeave(() => {
        set_chatInfo({
            show: false,
            state: '',
            question: '',
            answer: ''
        })
    })


    const toggleChat = () => {
        set_chatInfo(prev => ({
            ...prev,
            show: !prev.show,
            state: prev.show ? '' : 'user'
        }))
    }

    const scrollChatIntoView = () => {
        setTimeout(() => {
            chatRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
        }, 200)
    }

    const goToPaymentPage = async() => {
        if (!cart.cart_items.length)
            return console.warn('nothing in cart..')

        history.push(`/cart/${cart.id}/payment`)
    }
    
    useEffect(() => {
        create_cart_if_not_exist()
    }, [])


    return (
        <IonPage id='cartPage'>
            <IonHeader>
                <Header 
                    navigateStaticButton={true}
                    onStaticButtonClick={() => router.push('/home', 'back')}
                    showLocationText={true}
                />    
            </IonHeader>

            <IonContent id='cart-page-content' ref={contentRef} className={css.content}>
                <div className={`${css.fadeEffectTop}`} />

                <div className='dev absolute right-6 top-1 z-50' onClick={dev_addProductToCart}>add</div>
                <div className='dev absolute left-7 top-1 z-50' onClick={() => router.push('/product/1')}>detail</div>
                
                <CartProducts 
                    increaseProductCount={increaseProductCount}
                    decreaseProductCount={decreaseProductCount}
                    deleteProduct={deleteProduct}
                    cart_items={cart.cart_items}
                    set_cart={set_cart}
                    isScannerModalOpen={isScannerModalOpen}
                    contentRef={contentRef}
                    onProductItemClick={(product) => {
                        set_selectedProduct(product)
                        set_isProductDetailModalOpen(true)
                    }}

                    lastAddedProduct={lastAddedProduct}
                    lastScanProductId={lastScanProductId}
                    lastScanT={lastScanT}
                />

                {/* <div className={css.contentPadding}></div> */}

                {chatInfo.show &&
                    <Chatbox
                        reference={chatRef}
                        chatInfo={chatInfo}
                        onCloseChat={() => toggleChat()}
                        onUserAsk={() => {
                            set_chatInfo(prev => ({...prev, state: 'waiting'}))
                            scrollChatIntoView()
                        }}
                        onChatbotAnswer={(userQuestion, chatbotAnswer) => {
                            set_chatInfo(prev => ({
                                ...prev,
                                question: userQuestion,
                                answer: chatbotAnswer,
                                state: 'chatbot'
                            }))
                            scrollChatIntoView()
                        }}
                        onUserEnd={() => set_chatInfo({
                            show: false,
                            state: '',
                            question: '',
                            answer: ''
                        })}
                        onAgain={() => set_chatInfo(prev => ({
                            ...prev,
                            state: 'user',
                            question: '',
                            answer: ''
                        }))}
                        onError={() => {
                            set_chatInfo(prev => ({
                                ...prev,
                                state: 'chatbot',
                                answer: 'Prepáčte niečo sa pokazilo, skúste sa spýtať znova'
                            }))
                            scrollChatIntoView()
                        }}
                    />
                }

                <ProductDetail_modal 
                    isOpen={isProductDetailModalOpen}
                    product={selectedProduct}
                    onCloseIconClick={() => set_isProductDetailModalOpen(false)}
                    onDidDismiss={() => set_isProductDetailModalOpen(false)}
                />
                
                <BarcodeScanner_modal
                    isOpen={isScannerModalOpen}
                    onModalClose={() => {
                        set_isScannerModalOpen(false)
                        console.log('scrolled down')
                    }}
                    setIsModalOpen={set_isScannerModalOpen}
                    onSuccesScan={(productId) => {
                        set_lastScanProductId(productId)
                        set_lastScanT(prev => prev + 1)
                    }}
                />

                <AddPaymentCard_modal
                    onAddCard={() => set_showAddCardModalOpen(false)}
                    onCloseIconClick={() => set_showAddCardModalOpen(false)}
                    isOpen={showAddCardModalOpen} 
                />
                
            </IonContent>

            
                {/* <IonFooter className={!chatInfo.show ? css.withContent : css.withoutContent} > */}
                <IonFooter>
                    {chatInfo.state != 'user' && 
                        <div className={css.footer_container}>
                            
                            {!chatInfo.show &&
                                <div className={css.roundButtons}>
                                    <ChatToggleButton
                                        activeJarvis={chatInfo.show}
                                        onIconClick={toggleChat} 
                                    />
                                    <ScanBarcodeOrUseNfc onBarcodeIconClick={() => set_isScannerModalOpen(true)} /> 
                                </div>
                            }

                            <CartPaymentInfo 
                                paymentMethod={userInfo.paymentMethod}
                                totalPrice={cart.total_price} 
                                onAddCardClick={() => set_showAddCardModalOpen(true)}
                            /> 
                        
                            <IonSwipeButton
                                label='Slide to Checkout'
                                sliderBackground='linear-gradient(to right, var(--newPrimary) 20%, #7e97c6,  var(--newSecondary))'
                                carretImage={guest}
                                carretBorder='transparent'
                                carretBackground='linear-gradient(#9d9fa1, #9d9fa1) padding-box, linear-gradient(to bottom, var(--newPrimary), var(--newSecondary)) border-box'
                                maskBorder='#f6f6f6'
                                fontSize="12px"
                                disabled={!cart.cart_items.length || !userInfo.paymentMethod}
                                onSwipe={goToPaymentPage}
                                userPhoto={userInfo.photo}
                            />
                            
                            {!userInfo.paymentMethod &&
                                <div 
                                    className={css.add_card_alert} 
                                    onClick={() => alert('Please add a payment card')} 
                                />
                            }
                    
                        </div> 
                    }
                </IonFooter>
        </IonPage>
    )
}

export default CartPage