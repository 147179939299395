export const formatPrice = (number) => {
    if (!number) 
        return 0

    let result
    result = parseFloat(number)
    result = result.toFixed(2)

    return result
}

    


