import { apiProcess } from '../host/apiProcess'
import { createFetchParams } from '../host/createFetchParams'

export const apiPost_auth = async (url, payload, no_contentType = false) => {
    try {
        let fp = createFetchParams(url, 'POST', 'isAuth', payload, no_contentType)
        if (fp.error) 
            return {error: fp.error}

        const response = await fetch(fp.full_url, fp.options)
        if (!response.ok) {
            if (response.status == 401) {
                return {error: 'jwt expired'}
            }
            return {error: 'didnt respond with json, something is very broken'}
        }

        const data = await apiProcess(response)

        console.log('🐤 apiPost_auth with payload', fp.full_url, payload)
        return data

    } catch (error) {
        return {error: error}
    }
}