export const apiHost = () => {
    let x = 'http://127.0.0.1:8000/'
    if (import.meta.env.VITE_REACT_API_HOST) {
        x = import.meta.env.VITE_REACT_API_HOST
    }
    return x
}






