export const getFirstNameOrGuest = (fullName) => {
    if (!fullName) 
        return 'Guest'
    
    let fullname = fullName
    fullname = fullname.trimStart() // odstranenie medzery na zaciatku stringu
    const firstName = fullname.split(' ')[0]

    if (!firstName)
        return 'Guest'
    
    return firstName
}