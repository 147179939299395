import { IonContent, IonPage, IonFooter, IonHeader } from '@ionic/react'
import { useEffect, useState } from 'react'
import { useParams, useHistory } from "react-router-dom"
import Header from '~/app_layout/header/Header'
import AddToCart from '../addToCart/AddToCart'
import ProductDetail from '../detail/ProductDetail'
import ProductNotFound from '../notFound/ProductNotFound'
import useProductApi from './useProductApi'
import css from './ProductPage.module.css'

const ProductPage = () => {

    const { getProductById } = useProductApi()
    const [findedProduct, setFindedProduct] = useState(true)

    let { urlId } = useParams()
    
    let history = useHistory()

    const dev_promptForIdToOpen = async () => {
        let id = prompt()
        
        if (id) {
            history.push(`/product/${id}`)
        } else {
            alert('Empty input. Please enter the product id.') 
        }
    }

    const updateProduct = async () => {
        const resp = await getProductById(urlId)
        if (resp.error) {
            alert(resp.error)
            return
        }
        
        setFindedProduct(resp.product)
    }

    useEffect(() => {
        updateProduct()
    }, [urlId])
    
    return (
        <IonPage>
            <IonHeader>
                <Header
                    navigateStaticButton={true}
                    onStaticButtonClick={() => history.push('/cart')}
                    defaultHref='/cart'
                    showNavigationIcon={true}
                />    
            </IonHeader>

            <IonContent className={css.content}>                    
                {findedProduct
                    ?<ProductDetail product={findedProduct} />
                    :<ProductNotFound /> 
                }

                <div className='dev flex flex-wrap w-full'>
                    <div className='dev w-1/4 flex justify-center items-center'><span className='p-4 z-10 my-3' onClick={dev_promptForIdToOpen} >x</span></div>
                    <div className='dev w-1/4 flex justify-center items-center'><span className='p-4 z-10 my-3' onClick={() => history.push('/product/1')}>1</span></div>
                    <div className='dev w-1/4 flex justify-center items-center'><span className='p-4 z-10 my-3' onClick={() => history.push('/product/2')}>2</span></div>
                    <div className='dev w-1/4 flex justify-center items-center'><span className='p-4 z-10 my-3' onClick={() => history.push('/product/3')}>3</span></div>
                    <div className='dev w-1/4 flex justify-center items-center'><span className='p-4 z-10 my-3' onClick={() => history.push('/product/4')}>4</span></div>
                    <div className='dev w-1/4 flex justify-center items-center'><span className='p-4 z-10 my-3' onClick={() => history.push('/product/5')}>5</span></div>
                    <div className='dev w-1/4 flex justify-center items-center'><span className='p-4 z-10 my-3' onClick={() => history.push('/product/6')}>6</span></div>
                    <div className='dev w-1/4 flex justify-center items-center'><span className='p-4 z-10 my-3' onClick={() => history.push('/product/7')}>7</span></div>
                    <div className='dev w-1/4 flex justify-center items-center'><span className='p-4 z-10 my-3' onClick={() => history.push('/product/8')}>8</span></div>
                    <div className='dev w-1/4 flex justify-center items-center'><span className='p-4 z-10 my-3' onClick={() => history.push('/product/9')}>9</span></div>         
                </div>
                
            </IonContent>

            <IonFooter>
                {findedProduct && 
                    <AddToCart productId={findedProduct.id} />
                }
            </IonFooter>
                   
        </IonPage>
    )
}

export default ProductPage