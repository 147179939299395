import { useIonRouter } from '@ionic/react'
import { POST_customer_uploadPhoto } from './POST_customer_uploadPhoto'


const useProfile = () => {
    const router = useIonRouter()

    const navigateGoHome = () => {
        router.push('/home', 'back')
    }

    return {
        navigateGoHome,
    }
}

export default useProfile