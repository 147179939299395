import { useHistory } from 'react-router'
import { IonPage, IonContent, useIonViewDidEnter } from '@ionic/react'
import css from './WelcomePage.module.css'
import IonSwipeButton from '~/zzz_react/ionSwipe/IonSwipeButton'
import welcome1 from './_assets/welcome1.png'
import welcome2 from './_assets/welcome2.png'
import useUserSession from '~/app_layout/_session/useUserSession'
import { useEffect } from 'react'
import { preventNavigateSwipeBack } from '~/zzz_react/preventNavigateSwipeBack/preventNavigateSwipeBack'


const WelcomePage = () => {
    const history = useHistory()

    const { hasJwtSession, loadUserInfo, createGuestAccount } = useUserSession()

    const ensureUserSession = async () => {
        if (hasJwtSession()) {
            const resp = await loadUserInfo()
            if (resp.error) {
                //set_showErrorAlert(true)
                return
            }
            resp.verified ? history.replace('/home') : ''
        } else {
            const resp = await createGuestAccount()
            if (resp.error) {
                alert(resp.error)
                return
            }
            const resp2 = await loadUserInfo()
            if (resp2.error) {
                //set_showErrorAlert(true)
                return
            }
        }
    }

    const goToVerificationPhoneNumber = () => {
        history.push('/verification-phone-number')
    }

    useIonViewDidEnter(() => {
        preventNavigateSwipeBack('welcomePage')
    })

    useEffect(() => {
        console.log('vstup na welcome')
        ensureUserSession()
    }, [])

    return (
        <IonPage id='welcomePage'>
            <IonContent className={css.content}>
                <div className={css.img1}>
                    <img src={welcome1} alt='img_1' />
                </div>

                <div className={css.title}>
                    <h1>
                        Welcome to CUBE!
                        <p>
                            Your one app for all.
                        </p>
                    </h1>
                </div>

                {/* <WelcomeSwipeButton /> */}
                <IonSwipeButton
                    label='Sign Up!'
                    sliderBackground='linear-gradient(to right, var(--newPrimary) 15%, var(--bg4))'
                    carretBorder='transparent'
                    carretBackground='linear-gradient(#fff, #fff) padding-box, linear-gradient(to right, var(--newPrimary), var(--bg4)) border-box'
                    maskBorder='#fff'
                    onSwipe={goToVerificationPhoneNumber}
                />

                <div className='dev' onClick={() => history.push('/verification-phone-number')}>skip</div>
                <div className='dev relative top-8' onClick={() => history.push('/home')}>goHome</div>

                <div className={css.img2}>
                    <img src={welcome2} alt='img_2' />
                </div>
            </IonContent>
        </IonPage>
    )
}

export default WelcomePage