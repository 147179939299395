import { IonButton, IonSpinner } from '@ionic/react'
import css from './QrCodePayment.module.css'

const QrCodePayment = (props) => {
    return (
        <div className={`${css.wrapper} ${css[props.paymentMethod]}`}>
            {props.proccesing_qrCode
                ? <IonSpinner className={css.spinner} />
                : <img 
                    className={`${(props.isExpired || props.isBlurred) && css.blurEffect}`} 
                    src={props.qrCode} 
                />
            }

            {!props.proccesing_qrCode &&
                <>
                    {props.isExpired && 
                        <div className={css.expired}>
                            <p>
                                The QR code has expired.
                                <br /> 
                                Press refresh for new one please.
                            </p>
                            <IonButton onClick={() => props.onClickRefresh()}>
                                REFRESH
                            </IonButton>
                        </div>
                    }
                </>
            }
        </div>
    )
}

export default QrCodePayment