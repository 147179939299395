import { useEffect, useState } from 'react'
import { IonCard, IonListHeader, IonSkeletonText } from '@ionic/react'
import css from './RecentPurchases.module.css'
import { GET_customer_transactions } from './GET_customer_transactions'
import { formatDate } from './formatDate'


const RecentPurchases = (props) => {
    const [purchases, set_purchases] = useState([])
    const [isLoaded, set_isLoaded]= useState(null)

    const loadRecentPurchases = async () => {
        set_isLoaded(false)
        const resp = await GET_customer_transactions()
        if (resp.error) {
            alert(resp.error)
            return
        }
        set_purchases(resp.transactions)
        set_isLoaded(true)
    }

    useEffect(() => {
        if (!props.balance) 
            return
        
        loadRecentPurchases()  
    }, [props.balance, props.cart_id])

    return (
        <>
            <IonCard className={css.wrapper}>
                <div className={css.header}>
                    <h1>
                        Recent Purchases
                    </h1>
                    <div className={`fake ${css.see_all}`} >
                        See all
                    </div>
                </div>

                <div className={css.items_wrapper}> 
                    <div className={css.items}>
                        {!isLoaded
                            ? <IonListHeader>
                                <IonSkeletonText animated={true} style={{ width: '60%' }}></IonSkeletonText>
                            </IonListHeader>  
                            : purchases.length 
                                ? <>
                                    {purchases.map(purchase => (
                                        <div 
                                            key={purchase.id} 
                                            className={css.item}
                                        >
                                            <div className={css.logo}>
                                                DP
                                            </div>
                                            <div className={css.info}>
                                                <p className={css.storeName}>
                                                    Demo Prevadzka
                                                </p>
                                                <p className={css.timeOfPurchase}>
                                                    {formatDate(purchase.created_at)}
                                                </p>    
                                            </div>
                                            <div className={css.totalPrice} >
                                                <span>€</span>
                                                {purchase.amount}
                                            </div>
                                        </div>
                                    ))}
                                </>
                                : <div className={css.emptyText}>
                                    No purchases yet
                                </div>
                        }
                        
                    </div>
                    
                    <div className={css.fadeEffectTop} /> 

                    <div className={css.fadeEffectBottom} />
                </div> 
            </IonCard>
            
            <div className={css.gap} /> 
        </>
    )
}

export default RecentPurchases
