import { IonPage, IonRouterOutlet, IonSplitPane } from '@ionic/react'
import { Route, Redirect } from 'react-router-dom'
import Menu from '~/app_layout/menu/Menu'

import Welcome                     from '~/welcome/_layout/WelcomePage'
import Home                        from '~/home/_layout/HomePage'
import Profile                     from '~/profile/_layout/ProfilePage'
import UserVerificationPhoneNumber from '~/verificationPhoneNumber/_layout/UserVerificationPhoneNumber'
import UserVerificationSmsCode     from '~/verificationSmsCode/_layout/UserVerificationSmsCode'
import Cart                        from '~/cart/_layout/CartPage'
import Product                     from '~/product/_layout/ProductPage'
import Payment                     from '~/payment/_layout/PaymentPage'
import CartFinish                  from '~/cartFinish/_layout/CartFinishPage'
           
import Chat                        from '~/chat/_layout/ChatPage'
import AddLoyaltyCard              from '~/loyaltyCard/_layout/AddLoyaltyCard'


const Routes = () => {
    return (
        <IonPage>
            <IonSplitPane contentId='main'>

                <Menu />

                <IonRouterOutlet id='main'>

                    <Route exact path='/welcome'                   component={Welcome} />
                    <Route exact path='/verification-phone-number' component={UserVerificationPhoneNumber} />
                    <Route exact path='/verification-sms-code'     component={UserVerificationSmsCode} />
                    <Route exact path='/home'                      component={Home} />
                    <Route exact path='/profile'                   component={Profile} />
                    <Route exact path='/cart'                      component={Cart} />
                    <Route exact path='/product/:urlId'            component={Product} />
                    <Route exact path='/cart/:urlCartId/payment'   component={Payment} />
                    <Route exact path='/cart-finish'               component={CartFinish} />
  
                    <Route exact path='/chat'                      component={Chat} />
                    <Route exact path='/add-loyalty-card'          component={AddLoyaltyCard} />

                    <Redirect exact from='/' to='/welcome' />
                    
                </IonRouterOutlet>

            </IonSplitPane>
        </IonPage>
    )
}

export default Routes