import SummaryProductsItem from './item/SummaryProductsItem'
import css from './SummaryCartProducts.module.css'


const SummaryCartProducts = (props) => {
    return (
        <div className={css.wrapper} >
            <div className={css.list} >   
                {props.cart_items.map(product => (
                    <SummaryProductsItem
                        key={product.product_id} 
                        product={product}
                    />
                ))}
            </div>

            <div className={`${css.fadeEffectTop}`} /> 

            <div className={`${css.fadeEffectBottom}`} />
        </div>
    )
}

export default SummaryCartProducts