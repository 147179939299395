import { IonIcon } from '@ionic/react'
import { closeOutline } from 'ionicons/icons'
import ChatboxMessage from './message/ChatboxMessage'
import ChatInput from '~/chat/input/ChatInput'
import css from './Chatbox.module.css'


const Chatbox = (props) => {
    return (
        <div 
            ref={props.reference} 
            className={`${props.chatInfo.state == 'user' && css.focused} ${css.wrapper}`}   
        >
            {props.chatInfo.state == 'user' &&
                <div className={css.closeIcon} onClick={props.onCloseChat} >
                    <IonIcon icon={closeOutline} />
                </div>
            }

            {props.chatInfo.state == 'user' &&
                <ChatInput
                    onUserAsk={props.onUserAsk}
                    onChatbotAnswer={props.onChatbotAnswer}
                    onError={props.onError}
                />
            }

            {(props.chatInfo.state == 'chatbot' || props.chatInfo.state == 'waiting') &&
                <ChatboxMessage
                    chatInfo={props.chatInfo}
                    onUserEnd={props.onUserEnd}
                    onAgain={props.onAgain}
                />
            }
        </div>
    )
}

export default Chatbox