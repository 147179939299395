import { IonModal, IonContent, IonIcon } from '@ionic/react'
import css from './ProductDetail_modal.module.css'
import { close } from 'ionicons/icons'


const ProductDetail_modal = (props) => {
    return (
        <IonModal
            className={css.productDetail_modal}
            isOpen={props.isOpen}
            initialBreakpoint={0.75}
            breakpoints={[0, 0.75]}
            onDidDismiss={props.onDidDismiss}
        >
            <IonContent>
                <div onClick={() => props.onCloseIconClick()} className={css.closeScanner}>
                    <IonIcon icon={close} />
                </div>

                <div className={css.wrapper_product_detail}>
                    <div className={css.imgWrapper}>
                        {props.product.image_url &&
                            <img src={props.product.image_url} />
                        } 
                    </div>

                    <div className={css.headerItem}>
                        <h1>
                            {props.product.title}
                            <p>
                                {props.product.nominal_quantity}
                            </p>
                        </h1>
                        <div className={css.item}>
                            <div className={css.pricePerPiece}>
                                {props.product.unit_price}
                                <p>
                                    €
                                </p>
                            </div>    
                        </div>
                    </div>

                    <div className={css.contentItem}>    
                        <p className={css.infoText}>
                            {props.product.description}
                        </p>
                        <p>Read more</p>
                        <p>Nutrient Information</p>
                    </div>
                </div>
            </IonContent>
        </IonModal>
    )
}

export default ProductDetail_modal
