import { useRecoilState } from 'recoil'
import { cart_atom } from '~/cart/_layout/cart.atom'
import { useHistory } from 'react-router'

const useCartFinish = () => {
    const [cart, setCart] = useRecoilState(cart_atom)
    const history = useHistory()

    const getCartItems = () => {
        const cartItems = cart.cart_items
        return cartItems
    }

    const getTotalPrice = () => {
        const totalPrice = cart.total_price
        return totalPrice
    }

    const restartProcessOfShopping = () => {
        setCart({
            cart_items: [],
            total_price: 0,
            id: null,
        })
        history.push('/home')
    }

    const getReceiptCode = () => {
        if (!cart.id)
            return
        
        let receiptCode

        if (cart.id < 10) {
            receiptCode = '00' + cart.id
        } else if (cart.id > 9) {
            receiptCode = '0' + cart.id
        } else {
            receiptCode = cart.id
        }
        receiptCode = receiptCode.toString()

        return 'A' + receiptCode
    }

    const navigateToHome = () => {
        history.push('/home')
    }
    
    return {
        getCartItems,
        getTotalPrice,
        restartProcessOfShopping,
        navigateToHome,
        getReceiptCode,
        cart
    }
}

export default useCartFinish