import { createRoot } from 'react-dom/client'
import App from '~/app_layout/_layout/App'
import { RecoilRoot } from 'recoil'


const container = document.getElementById('root')
const root = createRoot(container!)


root.render(
  <RecoilRoot>
      <App />
  </RecoilRoot>
)