import css from './SummaryProductsItem.module.css'
import { formatPrice } from '~/zzz_formatting/numbers/fixToTwoDecimal'


const SummaryProductsItem = (props) => {
    return (
        <div className={css.wrapper} >
            <div className={css.product}>
                <div className={css.imgWrapper}>
                    <img src={props.product.image_url} />
                </div>

                <div className={css.info}>
                    <p className={css.title}>
                        {props.product.title}
                    </p>
                    <div className={css.nominalQuantity}>
                        {props.product.nominal_quantity}
                    </div>
                </div>

                <div className={css.counter} >
                    <div className={css.count}>
                        {props.product.quantity}
                    </div>
                </div>

                <div className={css.summary}>
                    <span className={css.currency}>€</span>
                    {formatPrice(props.product.unit_price * props.product.quantity)} {/* calcSummaryItemPrice */}
                </div>
            </div>
        </div>
    )
}

export default SummaryProductsItem