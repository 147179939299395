import { apiProcess } from '../host/apiProcess'
import { createFetchParams } from '../host/createFetchParams'

export const apiGet_auth = async (url, payload) => {
    try {
        let fp = createFetchParams(url, 'GET', 'isAuth', payload)
        if (fp.error) 
            return {error: fp.error}
     
        const response = await fetch(fp.full_url, fp.options)
        if (!response.ok) {
            if (response.status == 401) {
                return {error: 'jwt expired'}
            }
            return {error: 'didnt respond with json, something is very broken'}
        } 
        
        const data = await apiProcess(response)

        console.log('🐦 apiGet_auth', fp.full_url)
        console.log('🐦 ', data)

        return data
    } catch (error) {
        return {error: error}
    }    
}
//return apiX(url, 'GET', 'isAuth', payload, '🐦 apiGet_auth')
