import css from './ProductNotFound.module.css'
import ImgProductNotFound from './_assets/product-not-found.jpg'

const ProductNotFound = () => {
    return (
        <div className={css.wrapper}>
            <div className={css.imgWrapper}>
                <img src={ImgProductNotFound} />
            </div>
            <h1 className='mx-auto'>
                UNKNOWN PRODUCT
            </h1>
            <p className='!no-underline !text-center max-w-[75%] mx-auto'>
                Unfortunately, we dont have this product in our database
            </p>
        </div>
    )
}

export default ProductNotFound