export const getJwt_LS = () => {
    let jwt = localStorage.getItem('jwt')
    if (!jwt) 
        jwt = ''
  
    return jwt
}

export const setJwt_LS =(jwt) => {
    localStorage.setItem('jwt', jwt)
}

export const removeJwt_LS = () => {
    localStorage.removeItem('jwt')  
}