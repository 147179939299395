import useUserSession from '~/app_layout/_session/useUserSession'
import { POST_customer_paymentMethod_delete } from '../paymentMethods/POST_customer_paymentMethod_delete'


const useProfile_PaymentMethods = () => {

    const { loadUserInfo } = useUserSession()
    
    const removePaymentMethod = async (paymentMethodId) => {
        const resp = await POST_customer_paymentMethod_delete(paymentMethodId)
        if (resp.error) {
            alert(resp.error)
            return
        }
        loadUserInfo()
    }

    return {
        removePaymentMethod
    }
}

export default useProfile_PaymentMethods