import { Html5Qrcode } from 'html5-qrcode'
import { useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { dev_scannerState_atom } from './dev_scannerState_atom'


const useBarcodeScan = () => {
    const [scanner, setScanner] = useState(null)
    const [scanResult, setScanResult] = useState('')
    const [scanCount, setScanCount] = useState(0)
    const [isScanning, setIsScanning] = useState(false)

    const dev_set_scannerState = useSetRecoilState(dev_scannerState_atom)


    const setScanboxSizeDynamically = (viewfinderWidth, viewfinderHeight) => {
        // let minEdgePercentage = 0.75 // 70%
        let minEdgePercentage = 0.75 // 80%
        let minEdgeSize = Math.min(viewfinderWidth, viewfinderHeight)
        let qrboxSize = Math.floor(minEdgeSize * minEdgePercentage)
        return {
            width: qrboxSize,
            // height: qrboxSize
            height: qrboxSize / 2
        }
    }
    
    
    const getScannerConfig = (fps) => {
        return {
            fps: fps,
            qrbox: setScanboxSizeDynamically,
        }
    }


    // let _handleInputValue = (val) => {
    //     val = '' + val

    //     let m = val.match(/[0-9]+$/)
    //     let id = m && m[0]
    //     console.log('scanresult:   ',scanResult,'id:  ' ,id);
        
    //     if (id) {
    //         history.push(`/product/${id}`) // pridat produkt    
    //     }
    //     else {
    //         alert('Bad product id in: '+val)
    //         setTimeout(() => {
    //             scan('reader', _handleInputValue)
    //         }, 500)
    //     }
    // }


    const _onSuccessScan = async (scanner, decodedText) => { // interná onSuccesScan funkcia, ale celé by sa to pri refaktoringu asi aj mohlo dať preč

        // _handleInputValue(decodedText)

        //setScanCount(scanCount + 1)
        //setIsScanning(false)
        //setScanResult(decodedText)
        //setScanner(null)

        // await scanner.pause()
        //console.log('scanner sa pauzol')

       /*  setTimeout(() => {
            // scanner.resume()
            console.log('scanner pokracuje dalej')
        }, 2000) */
    
        //await scanner.stop()
        //scanner.clear()
    }


    const onScanFailed = async (scanner, err) => {
        console.log('scan failed')
        if (err) {
            console.warn(err)
        }
        setScanCount(scanCount + 1)
        setScanResult('SCAN FAILED')
        
        await scanner.stop()
        scanner.clear()

        setScanner(null)
        setIsScanning(false)
    }



    // onRepeatedScanOnce
    const scan = (videoContainer, onSuccessScan) => {
        console.log('scan start')
        
        const html5QrCode = new Html5Qrcode(videoContainer)
        setScanner(html5QrCode)
        setIsScanning(true)
        dev_set_scannerState('scanning'),
        html5QrCode
            .start(
                { facingMode: 'environment' },
                getScannerConfig(35),
                (decodedText) => {

                    //console.log('decodedText', decodedText)
                    
                    onSuccessScan(decodedText)
                    //_onSuccessScan(html5QrCode, trimmedDecodedText)
                }
            )
            .catch(err => onScanFailed(html5QrCode, err))
    }


    const stop = async () => {
        if (!scanner) 
            return 
        
        await scanner.stop()
        scanner.clear()
        console.log('close camera');
        setIsScanning(false)
        setScanResult('')
    }


    return {
        scan: scan,
        stop: stop,
        isScanning: isScanning,
        scanResult: scanResult,
        scanCount: scanCount
    }
}

export default useBarcodeScan