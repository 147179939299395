import { useSetRecoilState } from 'recoil'
import { useHistory } from "react-router-dom"
import { POST_user_chat_sendMessage } from '../_layout/POST_user_chat_sendMessage'
import { chatMessages_atom } from '../messages/chatMessages.atom'


const useChat_sendMessage = () => {
    const history = useHistory()
    const setChatMessages = useSetRecoilState(chatMessages_atom)

    const ask_andAddReply = async (question) => {
        _addQuestion(question)
                
        const resp = await _ask(question)
        if (resp.error) {
            alert(resp.error)
            return resp
        } 
        
        _addReply(resp.answer)
        
        if (resp.action) 
            _triggerAction_withTimeout(resp.action)
        
        return resp
    }

    const _ask = async (question) => {
        const resp = await POST_user_chat_sendMessage(question)
        return resp
    }

    const _addQuestion = (message) => {
        setChatMessages(prev => [...prev, {text: message, sender: 'user'}])
    }

    const _addReply = (message) => {
        setChatMessages(prev => [...prev, {text: message, sender: 'chatbot'}])
    }

    const _triggerAction_withTimeout = (action) => {
        
        setTimeout(() => {
            history.push(action ? action : '#')
        }, 1500)
    }
    
    return {
        ask_andAddReply
    }
}

export default useChat_sendMessage