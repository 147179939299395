import { createGesture } from '@ionic/react'

export const preventNavigateSwipeBack = (targetElement) => {
    console.log(targetElement);
    const page = document.getElementById(targetElement)
    const gesture = createGesture({
        el: page,
        threshold: 0,
        gestureName: 'my-gesture',
        gesturePriority: 40.5, // priority of swipe to go back is 40 
        //onMove: ev => console.log(ev)
    })
    gesture.enable(true)    
}