import { useState, useEffect, useRef } from 'react'
import { IonIcon, IonItemSliding, IonItem, IonItemOptions, IonItemOption } from '@ionic/react'
import css from './CartProductsItem.module.css'
import Discount from './discount/Discount'
import { formatPrice } from '~/zzz_formatting/numbers/fixToTwoDecimal'
import plusIcon from './_assets/plus.svg'
import minusIcon from './_assets/minus.svg'


const CartProductsItem = (props) => {
    const [showControls, setShowControls] = useState(false)
    const itemCart_ref = useRef(null)


    useEffect(() => {
        console.log('CHANGE ')
        if (!showControls) 
            return
        
        setTimeout(() => {
            setShowControls(false)
        }, 4000)
    }, [showControls])

    useEffect(() => {
        if (props.lastScanProductId == props.product.product_id) {
            setShowControls(true)
        }
    }, [props.lastScanT])

    useEffect(() => {
        if (props.lastAddedProduct.id == props.product.product_id) {
            // stara logika na scrolling, teraz to je pri updateItems(add to cart)

            // setTimeout(() => {
            //     const yOffset = itemCart_ref.current.offsetTop
            //     const autoOffset = props.isScannerModalOpen ? 50 : 100
            //     props.contentRef.current.scrollToPoint(0, yOffset - autoOffset, 500)
            // }, 300)
        }
    }), [props.lastAddedProduct]

    return (
        <IonItemSliding
            ref={itemCart_ref}
            id={props.product.product_id}
            className={css.itemSliding} 
            onIonSwipe={() => props.deleteProduct(props.product.product_id, itemCart_ref)} 
        >
            <IonItem lines='none'>
                <div className={css.wrapper} >
                    <div className={css.product}>
                        <div className={css.imgWrapper}>
                            <img src={props.product.image_url} />
                        </div>

                        <div className={css.info}>
                            <p className={css.title}>
                                {props.product.title}
                            </p>
                            <div className={css.nominalQuantity}>
                                {props.product.nominal_quantity}
                            </div>
                        </div>

                        <div className={css.counter} >
                            {!props.isSummary && !props.showControls &&
                                <div className={css.iconWrapper}>
                                    {/* <IonIcon icon={removeOutline} /> */}
                                </div>
                            }

                            <div className={css.count}>
                                {props.product.quantity}
                            </div>

                        {!props.isSummary && !props.showControls &&
                            <div className={css.iconWrapper}>
                            {/*  <IonIcon icon={addOutline} /> */}
                            </div>
                            }
                        </div>

                        <div className={`${css.summary} ${props.isSummary && css.summary_thankyou}`}>
                            <span className={css.currency}>€</span>
                            {formatPrice(props.product.unit_price * props.product.quantity)} {/* calcSummaryItemPrice */}
                        </div>
                    </div>

                    {props.product.discount == 1 && 
                        <Discount />
                    }

                    {showControls &&
                        <div className={css.plusMinusCounterBox}>
                            <div
                                onClick={() => props.decreaseProductCount(props.product.product_id)} 
                                className={css.iconWrapper}
                            >
                                <IonIcon icon={minusIcon} />
                            </div>

                            <div className={css.counter}>
                                {props.product.quantity}
                            </div>

                            <div
                                onClick={() => props.increaseProductCount(props.product.product_id)} 
                                className={css.iconWrapper}
                            >
                                <IonIcon icon={plusIcon} />
                            </div>
                        </div>
                    }

                        <div 
                            className={css.clickable_space_to_open_detail_modal}
                            onClick={props.onProductItemClick} 
                        />

                        <div 
                            className={css.clickable_space_to_show_controls}
                            onClick={() => setShowControls(true)}
                        />
                </div>
            </IonItem>

            <IonItemOptions side="end">
                <IonItemOption 
                    onClick={() => props.deleteProduct(props.product.product_id, itemCart_ref)} 
                    color="danger"
                    expandable
                >
                    Delete
                </IonItemOption>
            </IonItemOptions>
        </IonItemSliding> 
    )
}


export default CartProductsItem