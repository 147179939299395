import { useRecoilState, useSetRecoilState } from 'recoil'
import { useRef } from 'react'

import { cart_atom } from '~/cart/_layout/cart.atom' 
import { lastAddedProduct_atom } from '~/product/_layout/product.atom'
import { dev_scannerState_atom } from '~/cart/barcodeScanner_modal/dev_scannerState_atom'

import { POST_cart_updateItem } from './POST_cart_updateItem'
import { POST_create_cart } from './POST_create_cart'
import { GET_product_barcode } from '~/product/_layout/GET_product_barcode'
import { hapticsImpactLight } from '~/zzz_native/impacts/hapticsImpacts'


const useCart_UpdateItems = () => {
    const [cart, setCart] = useRecoilState(cart_atom)
    const set_lastAddedProduct = useSetRecoilState(lastAddedProduct_atom)
    const dev_set_scannerState = useSetRecoilState(dev_scannerState_atom)

    const _create_cart = async () => {
        if (cart.id) {
            console.error('Nemože volat create cart ked uz existuje')
        }
        
        const resp = await POST_create_cart()
        if (resp.error) {
            alert(resp.error)
            return 
        }
        
        console.log('New cart created successfuly', resp)
        setCart(resp.cart)
        return resp.cart.id
    }

    // Dočasne riesenie aby fungoval scanner lebo vyzera ze ignoruje atom stavy (konkretne cart.id) 
    const create_cart_if_not_exist = () => {
        if (!cart.id) {
            _create_cart()
        }
    }

    const addProductToCart = async (scannedBarcodeId, draftedCount, locationOfUse) => {
        dev_set_scannerState('loading')
        const resp = await GET_product_barcode(scannedBarcodeId)
        if (resp.error) {
            alert(resp.error)
            return 
        }

        let cartId
        if (!cart.id)  
            cartId = await _create_cart()

        // scrolling
        dev_set_scannerState('scrolling')
        set_lastAddedProduct(resp.product)
        const productElement = document.getElementById(resp.product.id)
        const cartPageContent = document.getElementById('cart-page-content')
        if (productElement && cartPageContent) {
            const yOffset = productElement.offsetTop
            cartPageContent.scrollToPoint(0, yOffset - 75, 500)
        } else if (cartPageContent) {
            cartPageContent.scrollToBottom(400)
        }

        if (locationOfUse == 'barcodeScanner') { // toto je tu na to, ze ked skenujem, tak nech vyhlada ci sa nachadza v list produktov,
                                                // a aby mi to fungovalo pridanie produktu z productDetailPage
            const isProductInCart = cart.cart_items.find(product => {
                //console.log(product.product_id)
                //console.log(resp.product.id)
                if (product.product_id == resp.product.id) {
                    return product
                } else {
                    return null
                }
            })

            setTimeout(() => {
                dev_set_scannerState('scanning')
            }, 300)
            console.log('je produkt v kosiku ?', isProductInCart)
            if (isProductInCart)
                return  
        } 

        setTimeout(() => {
            dev_set_scannerState('adding')
        }, 300)
        console.log('AddProductToCart - Odoslanie requestu')
        const resp2 = await POST_cart_updateItem(cartId ? cartId : cart.id, resp.product.id, draftedCount)
        if (resp2.error) {
            alert(resp2.error)
            return 
        }
        setCart(resp2.cart)
        console.log('Produkt sa pridal do kosika')
        setTimeout(() => {
            dev_set_scannerState('scanning')
        }, 400)
    }

    const deleteProduct = async (productId, itemRef) => {
        hapticsImpactLight()
        itemRef.current.className = 'hidden'

        const resp = await POST_cart_updateItem(cart.id, productId, -999)
        if (resp.error) {
            itemRef.current.className = 'block'
            alert(resp.error)
            return 
        }
        setCart(resp.cart)
    }
    
    return { 
        addProductToCart,
        create_cart_if_not_exist,
        deleteProduct
    }
}

export default useCart_UpdateItems