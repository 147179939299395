import { Haptics, ImpactStyle } from '@capacitor/haptics'

//dokumentacia - https://ionicframework.com/docs/native/haptics
export const hapticsImpactLight = async () => {
    await Haptics.impact({ style: ImpactStyle.Light })
}

export const hapticsImpactMedium = async () => {
    await Haptics.impact({ style: ImpactStyle.Medium })
}

export const hapticsImpactHeavy = async () => {
    await Haptics.impact({ style: ImpactStyle.Heavy })
}



