import { useState, useEffect } from 'react'
import { useParams } from "react-router-dom"
import { IonPage, IonHeader, IonContent, useIonViewWillLeave, IonIcon, IonButton, IonSpinner } from '@ionic/react'
import cink_sound from '../_layout/_assets/shortSound_1.mp3'
import { checkmarkOutline, closeOutline, informationCircleOutline } from 'ionicons/icons'
import css from './PaymentPage.module.css'
//import nfcLogo from './_assets/nfcLogo.png'
import scanLeadImg from './_assets/scanlead.png'
import QrCodePayment from '../qrCode/QrCodePayment'
import Header from '~/app_layout/header/Header'
import PaymentMethodsSegment from '../paymentMethods/PaymentMethodsSegment'
import PaymentErrorAlert from '../paymentErrorAlert/PaymentErrorAlert'
//import PaymentSuccessAlert from '../paymentSuccessAlert/PaymentSuccessAlert'
import useUserSession from '~/app_layout/_session/useUserSession'
import { GET_payAttempt_status } from './GET_payAttempt_status.api'
import { POST_terminal_processPayment } from './POST_terminal_processPayment'
import { preventNavigateSwipeBack } from '~/zzz_react/preventNavigateSwipeBack/preventNavigateSwipeBack'
import usePayment from './usePayment'
import { useRecoilValue } from 'recoil'
import { userInfo_atom } from '~/app_layout/_session/userInfo_atom'
import { hapticsImpactLight } from '~/zzz_native/impacts/hapticsImpacts'

const payInfoInitial = {
    payAttemptId: null, 
    totalPrice: 0, 
    cubeId: '', 
    qrCode: '',
}

let cinkSound = new Audio(cink_sound)
//let cinkSound = new Audio('http://sprott.physics.wisc.edu/wop/sounds/Bicycle%20Race-Full.m4a')
cinkSound.currentTime = 0.05

const PaymentPage = () => {
    
    // malo by to riesit toto -> https://stackoverflow.com/questions/56783395/disable-ios-safari-lock-screen-scrubber-for-media
    document.addEventListener('visibilitychange', () => {
        if (document.hidden) 
            cinkSound.src = undefined
    })
    
    const { cancelPayAttempt, setCartFailed, getPayInfo, setCart, navigateToCartFinish, cart } = usePayment()
    const { loadUserInfo } = useUserSession()
    let { urlCartId } = useParams()
    const userInfo = useRecoilValue(userInfo_atom)
    
    const [payInfo, set_payInfo] = useState(payInfoInitial)
    const [paymentMethod, setPaymentMethod] = useState('balance')
    const [showErrorAlert, set_showErrorAlert] = useState(false)
    //const [showSuccessAlert, set_showSuccessAlert] = useState(false)
    const [paymentState, setPaymentState] = useState('pending')
    const [isOrderPaid, setIsOrderPaid] = useState(false) // pozn. tuto premennu asi by malo byt mozne nahradit paymentState
    const [isExpired, setIsExpired] = useState(false)
    const [isBlurred, setIsBlurred] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    // proccesings
    const [devProcessing, set_devProcessing] = useState(false)
    const [proccesing_qrCode, set_proccesing_qrCode] = useState(false)


    const onErrorAlertSubmit = async () => {
        setIsBlurred(true)
        set_showErrorAlert(false)
        setCartFailed(urlCartId)
    }

    /* const onSuccessAlertSubmit = async () => {
        setCart({state: 'paid',  cart_items: [], total_price: 0, id: null})
        set_showSuccessAlert(false)
        const resp = await loadUserInfo()
        if (resp.error) 
            return

        navigateToCartFinish()
    } */


    const _checkPaymentStatus = async (payAttemptId) => {
        setIsExpired(false)
        preventNavigateSwipeBack('paymentPage')
        const resp = await GET_payAttempt_status(payAttemptId)
        if (resp.error) {
            if (resp.error_type == 'expired') {
                setPaymentState('expired')
                setIsExpired(true)
                return
            }

            setPaymentState('failed')
            setErrorMessage(resp.error)

            let wentBack = resp.error.startsWith('Payment cancelled')
            if (!wentBack)
                set_showErrorAlert(true)

            return
        }
        cinkSound.play()
        hapticsImpactLight()

        setPaymentState('success')
        setCart(prev => {return {...prev, state: 'paid'}})
        setIsOrderPaid(true)
        //set_showSuccessAlert(true)
        set_payInfo(payInfoInitial)
        const resp2 = await loadUserInfo()
        if (resp2.error) 
            return

        setTimeout(() => {
            navigateToCartFinish()
        }, 1000)
    }


    const startPayAttempt = async () => {
        setPaymentState('pending')
        //set_showSuccessAlert(false)
        set_showErrorAlert(false)
        setIsBlurred(true)
        set_proccesing_qrCode(true)
        // 2. param 'card' (paymentMethod type) je zatial hardcore, do buducna pojde dynamicky
        const resp = await getPayInfo(urlCartId, 'card', userInfo.paymentMethod.id)    // dev, kvoli refreshovaniu, v recoile nemusi byt po refreshi
        if (resp.error) {
            alert(resp.error)
            return
        }
        set_proccesing_qrCode(false)
        set_payInfo(resp)
        setIsBlurred(false) 

        _checkPaymentStatus(resp.payAttemptId)
    }

    const dev_simulateTerminalScan = async () => {
        set_devProcessing(true)
        const resp = await POST_terminal_processPayment(payInfo.cubeId)
        set_devProcessing(false)
        if (resp.error) {
            console.warn(resp.error)
            return
        }

        console.log(resp)
    }

    useEffect(() => {
        if (!urlCartId) {
            console.log('no urlCartId')
            return
        }
        if (cart.state == 'failed') {
            console.log('failed')
            return
        }

        setIsOrderPaid(false)
        startPayAttempt()
    }, [urlCartId])

    // useEffect(() => {
    //     if (!showSuccessAlert) {
    //         return
    //     }
    //     onSuccessAlertSubmit()
    // }, [showSuccessAlert])


    useIonViewWillLeave(() => {
        if (!cart.cart_items.length) 
            return
        if (isExpired) 
            return
        if (isOrderPaid)
            return

        cancelPayAttempt(payInfo.payAttemptId)
    }, [payInfo.payAttemptId, isOrderPaid, cart.cart_items.length, isExpired])
  
    return (
        <IonPage id='paymentPage'>
            <IonHeader>        
                <Header 
                    title={'Pay with Cube'} 
                    // goBackButton={!showErrorAlert && !showSuccessAlert} 
                    goBackButton={true}
                    defaultHref='/cart'
                    showLocationText={true}
                    backButtonDisabled={isOrderPaid}
                />
            </IonHeader>
            
            <IonContent className={css.content}>            
                {/* <div className={css.nfcLogo}>
                    <img src={nfcLogo} />
                </div> */}

                <div className={css.qrSpaceHolder}>
                    {(paymentState == 'pending' || paymentState == 'expired') &&
                        <QrCodePayment
                            paymentMethod={paymentMethod} 
                            isExpired={isExpired}
                            isBlurred={isBlurred}
                            qrCode={payInfo.qrCode}
                            onClickRefresh={startPayAttempt}
                            proccesing_qrCode={proccesing_qrCode}
                        />
                    }
                    {paymentState == 'success' && <>
                        <div className={css.successWrapper}>
                            <div className={css.iconWrapper}>
                                <IonIcon icon={checkmarkOutline} size='large' />
                            </div>
                            <h4>Payment Completed</h4>
                        </div>
                    </>}

                    {paymentState == 'failed' && <>
                        <div className={css.errorWrapper}>
                            <div className={css.iconWrapper}>
                                <IonIcon icon={closeOutline} size='large' />
                            </div>
                            <h4>Payment Failed</h4>
                        </div>
                    </>}
                </div>

                {/* <div className={css.totalPurchasePrice}>
                    {payInfo.totalPrice}€
                </div> */}

                {!showErrorAlert && 
                    <>
                    {/*  <PaymentMethodsSegment
                            last4={userInfo.paymentMethod.card.last4}
                            paymentMethod={paymentMethod}
                            onChangePaymentMethod={(selectedMethod) => {
                                setPaymentMethod(selectedMethod)
                            }}
                        /> */}
                        <div className={css.howToPay}>
                            <div onClick={() => alert('You can call the phone number 0944 022 380')} className={css.helpText}>
                                <IonIcon icon={informationCircleOutline} />
                                This Store require additional verification
                            </div>

                            <div className={css.scanLead}>
                                <img src={scanLeadImg} alt='How to scan qr code' />
                            </div>

                            <div className={css.infoText}>
                                Please scan the displayed QR code using the Cube Terminal to confirm your purchase and leave
                            </div>
                        </div>
                    </>
                }

                {paymentState != 'success' &&
                    <div className='dev mt-2' onClick={dev_simulateTerminalScan}>
                        'dev_simulateTerminalScan'
                        <br />
                        {devProcessing && 'processing...'}
                    </div>
                }

                {/* <PaymentMethodsSegment /> */}
                
                {/* <PaymentSuccessAlert 
                    isOpen={showSuccessAlert}
                    closeAlert={() => set_showSuccessAlert(false)}
                    onAlertSubmit={onSuccessAlertSubmit}
                /> */}

                <PaymentErrorAlert
                    isOpen={showErrorAlert}
                    closeAlert={() => set_showErrorAlert(false)}
                    errorMessage={errorMessage}
                    onAlertCancel={startPayAttempt}
                    onAlertSubmit={onErrorAlertSubmit} 
                />
            </IonContent>
        </IonPage>
    )
}

export default PaymentPage