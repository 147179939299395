import { IonAlert } from '@ionic/react'
import css from './PaymentErrorAlert.module.css'


const PaymentErrorAlert = (props) => {
    return (
        <IonAlert
            className={css.errorAlert}
            isOpen={props.isOpen}
            backdropDismiss={false}
            header={props.errorMessage}
            subHeader="Use normal cash register or cancel"
            buttons={[
                {
                    text: 'Try again',
                    role: 'cancel',
                    handler: () => {
                        props.onAlertCancel()
                        props.closeAlert()
                    },

                },
                {
                    text: 'Ok',
                    role: 'confirm',
                    handler: async () => {
                        props.onAlertSubmit()
                        props.closeAlert()
                    },
                },
            ]}
        />
    )
}
export default PaymentErrorAlert