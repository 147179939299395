import { useState, useEffect, useRef } from 'react'
import { IonContent, IonPage, IonButton, IonIcon, IonHeader, IonInput, IonSpinner, IonSelect, IonSelectOption } from '@ionic/react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { userInfo_atom } from '~/app_layout/_session/userInfo_atom'
import { mailOutline, pencilOutline } from 'ionicons/icons'
import css from './ProfilePage.module.css'
import Header from '~/app_layout/header/Header'
import useProfile from './useProfile'
import useUserSession from '~/app_layout/_session/useUserSession'
import useProfile_PaymentMethods from './useProfile_PaymentMethods'
import { POST_customer_updateInfo } from './POST_customer_updateInfo'
import { POST_customer_uploadPhoto } from './POST_customer_uploadPhoto'
import { FilePicker } from '@capawesome/capacitor-file-picker'
import guestAvatar from './_assets/guest.png'
import { getYearAndNameOfMonth } from '~/zzz_formatting/date/getYearAndNameOfMonth'
import PaymentMethods from '../paymentMethods/PaymentMethods'
import AddPaymentCard_modal from '../addPaymentCard_modal/AddPaymentCard_modal'
import { showAddCardModalOpen_atom } from '../addPaymentCard_modal/showAddCardModalOpen_atom'


const ProfilePage = () => {
    const { navigateGoHome } = useProfile()
    const { loadUserInfo } = useUserSession()
    const { removePaymentMethod } = useProfile_PaymentMethods()
    const userInfo = useRecoilValue(userInfo_atom)
    const [isAddCardModalOpen, set_isAddCardModalOpen] = useRecoilState(showAddCardModalOpen_atom)

    const phone_ref = useRef(null)

    //const [imageFile, set_imageFile] = useState(new File([], '')) //file
    const [imageFile, set_imageFile] = useState(null) //file

    const [proccesing, set_proccesing] = useState(false)

    // USER INFO - LOCAL STATE
    const [name, set_name] = useState('')
    const [phone, set_phone] = useState('')
    const [email, set_email] = useState('')
    const [callingCode, set_callingCode] = useState('+421')

    const czFlag = '🇨🇿'
    const skFlag = '🇸🇰' //vsade okrem windowsu to je vidiet, https://emojipedia.org/flag-slovakia#designs
    // skusali sme img do ionSelect ale nezobrazuje sa html, tak preto emoji
    
    const _loadUserInfo = async () => {
        const resp = await loadUserInfo()
        if (resp.error) {
            alert(resp.error)
            return 
        }
        set_name(resp.name)
        set_email(resp.email)
        set_imageFile(resp.photo)
        
        let phone = resp.phoneNumber
        if (!phone) {
            //alert('Phone number doesn´t exist: ', phone)
            return
        }

        let callingCode = ''
        callingCode = phone.replace(/.{9}$/, '')
        //phone = phone.replace(/^\+421/, '')
        phone = phone.replace(/^.{4}/, '')

        set_phone(phone)
        set_callingCode(callingCode)
    }

    const pickAndUploadPhoto = async () => {
        const result = await FilePicker.pickImages({
          multiple: false,
          readData: true,
        })

        let file = result.files[0]

        let imageData = new FormData()
        if (file.blob) {
            const rawFile = new File([file.blob], file.name, { type: file.mimeType })
            imageData.append('image', rawFile)
        }

        const resp = await POST_customer_uploadPhoto(imageData)
        if (resp.error) {
            alert(resp.error)
            return
        }
        console.log(resp)
        _loadUserInfo()
    }
    

    const updateUserInfo_andGoBack = async (name, phone, email) => {
        let fullPhone

        if (!phone) {
            fullPhone = ''
        } else {
            fullPhone = callingCode + phone    
        }
        set_proccesing(true)
        const resp = await POST_customer_updateInfo(name, fullPhone, email)
        set_proccesing(false)
        if (resp.error) {
            alert(resp.error)
            return 
        }
        
        const resp2 = await loadUserInfo()
        if (resp2.error) {
            alert(resp2.error)
            return 
        }
        
        navigateGoHome()
    }

    useEffect(() => {
        _loadUserInfo()
    }, [])

    const setFocusNextInput = (e) => {
        if (e.key === 'Enter') { // v mobile to reaguje na click on ok vo virtual keyboard
            //selectCallingCode_ref.current.setFocus()
            phone_ref.current.focus()
        } 
    }

    return (
        <IonPage>
            <IonHeader>
                <Header
                    goBackButton={true}
                    defaultHref='/home'
                    title='Profile'
                />    
            </IonHeader>
             
            <IonContent className={css.content}>
                <div className={css.userAvatar}>
                    <div className={css.photo_wrapper}>
                        <img
                            src={imageFile ? imageFile : guestAvatar}
                            alt='customer profile photo'
                            onClick={pickAndUploadPhoto}
                        />
                    </div>

                    <IonButton className={css.editUserPhoto} onClick={pickAndUploadPhoto}>
                        <IonIcon slot='icon-only' icon={pencilOutline} />
                    </IonButton>
                </div>

                <div className={css.profileForm}>
                    <div className={css.namesWrapper}>
                        <IonInput
                            type='text'
                            value={name}
                            onIonInput={(e) => set_name(e.target.value)}
                            fill='outline' 
                            placeholder='Full Name'
                            onKeyUp={setFocusNextInput}
                        />
                    </div>
                    <div className={css.phoneWrapper}>
                        <IonSelect
                            value={callingCode}
                            onIonChange={(e) => set_callingCode(e.detail.value)}
                        >
                            <IonSelectOption value='+421'>{skFlag} + 421</IonSelectOption>
                            <IonSelectOption value='+420'>{czFlag} + 420</IonSelectOption>
                        </IonSelect>

                        <IonInput
                            ref={phone_ref} 
                            type='text'
                            value={phone}
                            onIonInput={(e) => set_phone(e.target.value)}
                            fill='outline' 
                            placeholder='9xx xxx xxx'
                            inputmode='numeric'
                        />

                        {/* {!userInfo.verified &&
                            <IonButton onClick={navigateToEnterYourNumber} className={css.verifyButton}>
                                VERIFY
                            </IonButton>
                        } */}
                    </div>

                    <div className={css.emailWrapper}>
                        <IonInput
                            type='email'
                            value={email}
                            onIonInput={(e) => set_email(e.target.value)}
                            fill='outline' 
                            placeholder='Add your email adress'
                        />
                        <IonIcon icon={mailOutline} />
                    </div>

                    <p className={css.registerInfo}>
                        Cube member since&nbsp;
                        <span>
                            {getYearAndNameOfMonth()}
                        </span>
                    </p>

                    <IonButton 
                        className={css.saveButton} 
                        onClick={() => updateUserInfo_andGoBack(name, phone, email, callingCode)}
                        disabled={proccesing}
                    >
                        {proccesing ? <IonSpinner className={css.spinner} /> : 'SAVE'}
                    </IonButton>

                </div>

                <PaymentMethods
                    paymentMethod={userInfo.paymentMethod}
                    onAddCardClick={() => set_isAddCardModalOpen(true)}
                    onRemoveCardIconClick={() => removePaymentMethod(userInfo.paymentMethod.id)} 
                />

                <AddPaymentCard_modal
                    onAddCard={() => {
                        //set_wantAddCardFromShopCart(false)
                        set_isAddCardModalOpen(false)

                        //if (!wantAddCardFromShopCart) 
                        //    return
                        //navigateGoBack()
                    }}
                    onCloseIconClick={() => {
                        //set_wantAddCardFromShopCart(false)
                        set_isAddCardModalOpen(false)
                    }}
                    isOpen={isAddCardModalOpen} 
                />
                
            </IonContent>         
        </IonPage>
    )
}

export default ProfilePage