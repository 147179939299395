import { useRecoilState } from 'recoil'
import { GET_user_chat } from './GET_user_chat'
import { chatMessages_atom } from '../messages/chatMessages.atom'


const useChat_messages = () => {
    const [chatMessages, setChatMessages] = useRecoilState(chatMessages_atom)

    const loadMessages = async () => {
        const resp = await GET_user_chat()
        
        return resp
    }

    return {
        loadMessages,
        chatMessages,
        setChatMessages
    }
}

export default useChat_messages