import { api } from '~/zzz_api/core/api'

export const POST_auth_guest = () => {
    
    return api.post('api/auth/guest')
} 
   
        
       


