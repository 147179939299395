import { IonButton, IonIcon } from '@ionic/react'
import { useState, useEffect, useRef } from 'react'
import { arrowUp } from 'ionicons/icons'
import css from './ChatInput.module.css'
import useChat_sendMessage from './useChat_sendMessage'
import jarvisIcon from '~/chat/_botAvatar/_assets/jarvis2.png'


const ChatInput = (props) => {
  let [message, setMessage] = useState('')
  const inputRef = useRef(null)

  const { ask_andAddReply } = useChat_sendMessage()
  
  const submitTextMessage = async () => {
    console.log(message)
    console.log('sending message')
    props.onUserAsk()
    const resp = await ask_andAddReply(message)
    if (resp.error) {
      setTimeout(() => {
        props.onError()
      }, 1500)
      return
    }

    props.onChatbotAnswer(resp.question, resp.answer)
    setMessage('')
  }

  const onKeyUpEnter_submitTextMessage = (e) => {
    if (e.key != 'Enter') 
      return

    submitTextMessage()
  }


  useEffect(() => {
    inputRef.current.focus()
  }, [])

  return (
    <div className={css.wrapper}>
      <div className={css.header}>
        <div className={css.innerContent}>
          <img src={jarvisIcon} alt="bot avatar" />
          How can I help you today ?
        </div>
      </div>

      <div className={css.inputWrapper}>
        <input
          ref={inputRef}
          type='text'
          className={css.chatInput}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyUp={(e) => message && onKeyUpEnter_submitTextMessage(e)} 
        />

        <IonButton onClick={() => message && submitTextMessage()} >
          <IonIcon icon={arrowUp} />
        </IonButton>
      </div>
    </div>
  )
}

export default ChatInput