import { apiHost } from '~/zzz_api/host/apiHost'
import css from './ProductDetail.module.css'


const ProductDetail = (props) => {
    return (
        <div className={css.wrapper}>
            <div className={css.imgWrapper}>
                {props.product.image_url &&
                    <img src={apiHost() + props.product.image_url} />
                } 
            </div>

            <div className={css.headerItem}>
                <h1>
                    {props.product.title}
                    <p>
                        {props.product.nominal_quantity}
                    </p>
                </h1>
                <div className={css.item}>
                    <div className={css.pricePerPiece}>
                        {props.product.unit_price}
                        <p>
                            €
                        </p>
                    </div>    
                </div>
            </div>

            <div className={css.contentItem}>    
                <p className={css.infoText}>
                    {props.product.description}
                </p>
                <p>Read more</p>
                <p>Nutrient Information</p>
            </div>
        </div>
    )
}

export default ProductDetail