import { getJwt_LS } from '~/app_layout/_session/jwt_LS.util'
import { getFullUrl } from './getFullUrl'

export const createFetchParams = (url, method, isAuth, payload, no_contentType = false) => {

    const jwt = getJwt_LS()
    const full_url = getFullUrl(url)
    
    if (method != 'GET' && method != 'POST') {
        alert('method: ' + method + ' is not valid  ' + full_url)
        return {error: 'method: ' + method + ' is not valid  ' + full_url}
    }

    let options = {
        headers: no_contentType
            ? {
            }
            : {
                //'Content-Type': 'image/png',
                'Content-Type': 'application/json',
            },
    }

    options.method = method

    if (isAuth) {
        options.headers['Authorization'] = `Bearer ${jwt}`
        //options.headers.Authorization = `Bearer ${config.token}`
        //options['headers'].Authorization = `Bearer ${config.token}`
    }

    if (method == 'POST') 
        options.body = no_contentType ? payload : JSON.stringify(payload)
        // options.body = JSON.stringify(payload)
    
    const fetchParams = { full_url, options }

    return fetchParams
}
