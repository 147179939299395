import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react'
import { IonReactHashRouter } from '@ionic/react-router'
import Routes from '~/app_routes/routes/Routes'
import useCustomDeepLink from '~/app_routes/_deepLinks/useCustomDeepLink'
import { lockPortrait } from '~/zzz_react/lockScreen/lockPortrait'


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'

import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Ionic CSS */
import '../_theme/ionic.css'

/* Custom theme variables */
import '../_theme/variables.css'

/* Tailwind */
import '../_theme/tailwind.css'

/* Dev CSS */
import '~/zzz_dev/css/dev.css'
import '~/zzz_dev/css/fake.css'


setupIonicReact({
  innerHTMLTemplatesEnabled: true
})

const App = () => {
  //lockPortrait()
  useCustomDeepLink()

  return(
    <IonApp>
      <IonReactHashRouter>
        <IonRouterOutlet>
          <Routes />
        </IonRouterOutlet>
      </IonReactHashRouter>
    </IonApp>
  )
}

export default App
