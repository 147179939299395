import { IonContent, IonPage, IonHeader } from '@ionic/react'
import css from './ChatPage.module.css'
import Header from '~/app_layout/header/Header'
import BotAvatar from '../_botAvatar/BotAvatar'
import ChatMessages from '../messages/ChatMessages'
import ChatOptionsSlider from '../_slider/ChatOptionsSlider'
import ChatInput from '../input/ChatInput'

const ChatPage = () => {
  return (
    <IonPage>
      <IonHeader>
        <Header 
          goBackButton={true}
          defaultHref='/cart'
          showNavigationIcon={true}
          showLocation={true}
        />
      </IonHeader>   

      <IonContent>
        <section>
          
          <div className={css.topItem}>
            <BotAvatar />
            <ChatMessages />
          </div>

          <div className={css.bottomItem}>
            <ChatOptionsSlider />
            <ChatInput />
          </div>

        </section>
      </IonContent>
    </IonPage>
  )
}

export default ChatPage
