import { useEffect, useState } from 'react'
import { IonPage, IonContent, IonHeader, IonInput, IonSelectOption, IonSelect, IonButton, IonSpinner } from '@ionic/react'
import { useRecoilValue } from 'recoil'
import { userInfo_atom } from '~/app_layout/_session/userInfo_atom'
import Header from '~/app_layout/header/Header'
import css from './UserVerificationPhoneNumber.module.css'
import { POST_customer_verification_sendSms } from './POST_customer_verification_sendSms'
import { useHistory } from 'react-router'
import { useSetRecoilState } from 'recoil'
import { smsSentInfo_atom } from './smsSentInfo_atom'



const UserVerificationPhoneNumber = () => {
    const history = useHistory()
    const userInfo = useRecoilValue(userInfo_atom)
    const set_smsSentInfo = useSetRecoilState(smsSentInfo_atom)

    const [processing, set_processing] = useState(false)
    const [callingCode, set_callingCode] = useState('+421')
    const [phone, set_phone] = useState('')

    const czFlag = '🇨🇿'
    const skFlag = '🇸🇰' //vsade okrem windowsu to je vidiet, https://emojipedia.org/flag-slovakia#designs
                        // skusali sme img do ionSelect ale nezobrazuje sa html, tak preto emoji

    const setPhoneAndCallingCode = () => {
        let phone = userInfo.phoneNumber

        if (!phone) 
            return

        let callingCode = ''
        callingCode = phone.replace(/.{9}$/, '')
        phone = phone.replace(/^.{4}/, '')

        set_phone(phone)
        set_callingCode(callingCode) 
    }

    const submitSendPhoneNumber = async () => {
        if (!callingCode && !phone) {
            alert('Set your calling code and phone please')
            return
        }
        let fullPhone = callingCode + phone
        
        set_processing(true)
        const resp = await POST_customer_verification_sendSms(fullPhone)
        if (resp.error) {
             alert('Failed to send SMS', resp.error)
             set_processing(false)
             return
        }
        set_processing(false)
        console.log(resp)

        set_smsSentInfo(prev => { 
            return {
                ...prev,
                phoneNumber: callingCode + ' ' + phone, 
                verificationId: resp.verificationId 
            }
        })
        //alert('A verification code has been sent to your phone number. It usually comes within 10 seconds.')
        history.push('/verification-sms-code')
    }
    
    useEffect(() => {
        setPhoneAndCallingCode()
    }, [])
    
    return (
        <IonPage>
            <IonHeader>
                <Header 
                    goBackButton={true}
                    defaultHref='/welcome'
                    showNavigationIcon={true}
                />
            </IonHeader>   

            <IonContent>
                <div className={css.header}>
                    <h1>
                        Enter your number
                    </h1>
                    <div>
                        We will sent an SMS code for verification
                    </div>
                </div>

                <div className={css.phoneWrapper}>
                    <IonSelect 
                        value={callingCode}
                        onIonChange={(e) => set_callingCode(e.detail.value)}
                    >
                        <IonSelectOption value='+421'>{skFlag} + 421</IonSelectOption>
                        <IonSelectOption value='+420'>{czFlag} + 420</IonSelectOption>
                    </IonSelect>

                    <IonInput
                        type='text'
                        value={phone}
                        onIonInput={(e) => set_phone(e.target.value)}
                        fill='outline' 
                        placeholder='9xx xxx xxx'
                        inputmode='numeric'
                    />
                </div>

                <IonButton
                    className={css.sendSmsButton} 
                    onClick={submitSendPhoneNumber} 
                    disabled={processing}
                >
                    {processing 
                        ? <IonSpinner />
                        : 'SEND SMS'
                    }
                </IonButton>

                <span className='dev ml-6 py-3' onClick={() => set_phone('944022380') }>dev_roman</span>
                <span className='dev ml-12 py-3' onClick={() => history.push('/verification-sms-code') }>skip</span>
            </IonContent>
        </IonPage>
    )
}

export default UserVerificationPhoneNumber