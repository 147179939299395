import avatar from './_assets/jarvis.png'
import css from './BotAvatar.module.css'

const BotAvatar = () => {
  return (
    <div className={css.botItem}>
      <div className={css.avatar}>
        <img src={avatar} />
      </div>

      <div className={css.offer}>
        <h2>
          Artur
        </h2>
        <p>
          Virtual Shopping Assistant @ Yeme
        </p>
      </div>
    </div>
  )
}

export default BotAvatar