import { IonButton, IonIcon, useIonViewWillEnter } from '@ionic/react'
import { useState } from 'react'
import css from './AddToCart.module.css'
import { addCircleOutline, removeCircleOutline } from 'ionicons/icons'
import useCart_UpdateItems from '~/cart/_layout/useCart_UpdateItems'

const AddToCart = (props) => {
    
    const [draftedCount, setDraftedCount] = useState(1)
    const { addProductToCart } = useCart_UpdateItems()
    
    useIonViewWillEnter(() => {
        setDraftedCount(1)
    })

    return (      
        <div className={css.footer}>
            <div className={css.counter}>
                <div>
                    <IonIcon
                        onClick={() => setDraftedCount(prev => prev == 1 ? 1 : prev - 1)}
                        className={draftedCount == 1 ? `${css.disabled}` : null} 
                        icon={removeCircleOutline} 
                        size='large' 
                    />
                    <p>
                        {draftedCount}
                    </p>
                    <IonIcon 
                        onClick={() => setDraftedCount(prev => prev + 1)}
                        icon={addCircleOutline} 
                        size='large'
                    />
                </div>

                <IonButton
                    onClick={() => addProductToCart(props.productId, draftedCount)}
                    routerLink='/cart' 
                    routerDirection='forward'
                >
                    <div>
                        Add to bag
                    </div>
                </IonButton>
            </div>
        </div>     
    )
}

export default AddToCart