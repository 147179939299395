import { App } from '@capacitor/app'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { extractProductId } from '~/product/_utils/extractProductId'

const useCustomDeepLink = () => {
  const history = useHistory()

  useEffect(() => {        
    App.addListener("appUrlOpen", (event) => {
      let productId = extractProductId(event.url)
      if (!productId) {
        history.push(`/`)
        // alert('bad product id')
        return
      }
      history.push(`/product/${productId}`)
    })
  }, [])
}

export default useCustomDeepLink
