import { IonAlert } from '@ionic/react'

const ErrorAlert = (props) => {
  return (
    <IonAlert
      header="User not found!"
      subHeader='Do you want reset user ?'
      isOpen={props.isOpen}
      backdropDismiss={false}
      buttons={[
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            props.onErrorAlertCancel()
          },
        },
        {
          text: 'OK',
          role: 'confirm',
          handler: () => {
            props.onErrorAlertSubmit()
          },
        },
      ]}
    />
  )
}
export default ErrorAlert