import { useState, useEffect } from 'react'
import { IonModal, IonContent, useIonViewWillLeave, IonImg, IonIcon } from '@ionic/react'
import { cart_atom } from '../_layout/cart.atom'
import { close } from 'ionicons/icons'
import useBarcodeScan from './useBarcodeScan'
import useCart_UpdateItems from '~/cart/_layout/useCart_UpdateItems'
import css from './BarcodeScanner_modal.module.css'
import barcodeImg from './_assets/barcode.png'
import { useRecoilState, useRecoilValue } from 'recoil'
import { hapticsImpactMedium } from '~/zzz_native/impacts/hapticsImpacts'
import { dev_scannerState_atom } from './dev_scannerState_atom'


const BarcodeScanner_modal = (props) => {
    const cart = useRecoilValue(cart_atom)
    const [dev_scannerState, dev_set_scannerState] = useRecoilState(dev_scannerState_atom)
    const [scannedIdToProcess, set_scannedIdToProcess] = useState('')

    const { scan, stop, isScanning } = useBarcodeScan()
    const { addProductToCart } = useCart_UpdateItems()


    useEffect(() => {
        if (!scannedIdToProcess)
            return

        console.log('PROCESUJEM scannedIdToProcess..')
        addProductToCart(scannedIdToProcess, 1, 'barcodeScanner')
        console.log('PROCESOL SOM scannedIdToProcess..')

        hapticsImpactMedium()

        set_scannedIdToProcess('')

    }, [scannedIdToProcess])


    const closeModal = async () => {
        if (isScanning) 
            await stop()
        props.setIsModalOpen(false)
    }
    
    useIonViewWillLeave(() => {
        if (isScanning)
            stop()
        props.setIsModalOpen(false)
    })

    
    let lastScannedValue = ''
    let lastTimestamp = 0
    let timeoutId = null

    const onSuccesScan = async (decodedValue) => {
        clearTimeout(timeoutId)
        decodedValue = '' + decodedValue

        const matches = decodedValue.match(/[0-9]+$/)

        let productId = matches && matches[0]
        productId.trim()

        if (productId == 0) 
            return
        
        if (productId) {
            props.onSuccesScan(productId)

            console.log(parseFloat(productId), 'currentScannedId' )
            console.log( parseFloat(lastScannedValue), 'lastScannedId')
            const newTimestamp = Date.now()
            console.log(newTimestamp - lastTimestamp, 'rozdiel current a last T')

            timeoutId = setTimeout(() => {
                dev_set_scannerState('scanning')
            }, 500)

            // if (newTimestamp - lastTimestamp > 500) {
            //     dev_set_scannerState('scanning')
            // }

            if (newTimestamp - lastTimestamp < 500 || productId == lastScannedValue) {
                dev_set_scannerState('ignoring')
                return
            }

            lastTimestamp = newTimestamp 

           /*  if (productId == lastScannedValue) {
                dev_set_scannerState('ignoring')
                return
            } */

            lastScannedValue = productId

            setTimeout(() => {
                console.warn('cart id ', cart.id)
                //addProductToCart(productId, 1)
                set_scannedIdToProcess(productId)
            }, 0)

        } else {
            console.warn('Bad product id in: ' + decodedValue)
           /*  setTimeout(() => {
            console.log('SCAN 1111')
                scan('reader', onSuccesScan)
            }, 500) */
        }
    }

    const onWillDismissModal = () =>{
        props.setIsModalOpen(false)
        stop()
    }

    useEffect(() => {
        if (!props.isOpen) 
            return    
        
        setTimeout(() => {
            console.log('SCAN 1')
            scan('reader', onSuccesScan)//, onRepeatedScanOnce)    
        }, 100)
    }, [props.isOpen])

    return (
        <IonModal
            isOpen={props.isOpen}
            backdropBreakpoint={0.6} //backdropBreakpoint a initialBreakpoint maju byt rovnake hodnoty
            initialBreakpoint={0.6}
            breakpoints={[0, 0.33, 0.6]}
            backdropDismiss={false}
            onWillDismiss={onWillDismissModal}
        >
            <IonContent className={css.page}>
                <div className='dev absolute top-4 w-max left-1/2 -translate-x-1/2 z-50 bg-white text-lg px-1'>
                    {dev_scannerState}
                </div>

                <div onClick={closeModal} className={css.closeScanner}>
                    <IonIcon icon={close} />
                </div>

                <div className={css.scannerWrapper}>
                    <div id='reader' className={css.reader} />
                </div>

                <div> 
                    <div className={css.scannerInfo}>
                        <p>You can also bring your phone to a shelf label and click on the displayed notification</p>
                        <div className={css.labels}>
                            {/* <IonIcon icon={barcodeOutline} /> */}
                            <IonImg src={barcodeImg} alt='barcode img' />
                            <IonImg src={barcodeImg} alt='barcode img' />
                            <span>Scanner</span>
                        </div>
                    </div>
                </div>

                {/* <IonButton className={css.title}>
                    Scan Barcode
                </IonButton> */}
            </IonContent>
        </IonModal>
    )
}

export default BarcodeScanner_modal
