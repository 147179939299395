
// original: data.match(/\/app\/product\/\d+/)[0]

export const extractProductId = (extractableId) => {
    extractableId = '' + extractableId

    let m = extractableId.match(/[\d]+$/)
    if (m)
        return m[0]+''

    return ''
}
