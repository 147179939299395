import css from './ChatToggleButton.module.css'
import jarvisIcon from '~/chat/_botAvatar/_assets/jarvis2.png'


const ChatToggleButton = (props) => {
    return (
        <div 
            slot='end'
            className={`${props.activeJarvis && css.active} ${css.iconWrapper}`}
            onClick={props.onIconClick}
        >
            <img className={css.jarvisIcon} src={jarvisIcon} />
        </div>
    )
}

export default ChatToggleButton