import { useHistory } from 'react-router'
import { useRecoilState } from 'recoil'
import { cart_atom } from '~/cart/_layout/cart.atom'
import { POST_payAttempt_cart } from './GET_cart_payment.api'
import { POST_payAttempt_cancel } from './POST_payAttempt_cancel'
import { POST_cart_close } from './POST_cart_close.api'


const usePayment = () => {
    const history = useHistory()
    const [cart, setCart] = useRecoilState(cart_atom)


    const navigateToCartFinish = () => {
        history.push('/cart-finish')
    }


    const cancelPayAttempt = async (payAttemptId) => {
        const resp = await POST_payAttempt_cancel(payAttemptId)
        if (resp.error) {
            alert(resp.error)
            return
        }
        console.log(resp)
    }


    const setCartFailed = async (urlCartId) => {
        const resp = await POST_cart_close(urlCartId)
        if (resp.error) {
            alert(resp.error)
            return 
        }
        setCart({state: 'failed', cart_items: [], total_price: 0, id: null})

        console.log('Cart was failed')
        navigateToCartFinish()
    }


    const getPayInfo = async (urlCartId,paymentMethod, paymentMethodId) => {
        const resp = await POST_payAttempt_cart(urlCartId, paymentMethod, paymentMethodId)
        console.log(resp.cubeId)
        return resp
    }
 
    return {
        cancelPayAttempt,
        setCartFailed,
        getPayInfo,
        navigateToCartFinish,
        setCart,
        cart,
    }
}

export default usePayment