import { IonButton, IonIcon} from '@ionic/react'
import { barcodeOutline } from 'ionicons/icons'
import css from './ScanBarcodeOrUseNfc.module.css'


const ScanBarcodeOrUseNfc = (props) => {

    return (
        <IonButton
            className={css.scanButton} 
            shape='round'
            onClick={() => props.onBarcodeIconClick()} 
        >
            <IonIcon
                slot='icon-only'
                icon={barcodeOutline}
                alt='barcode image'
            />
        </IonButton>
    )
}

export default ScanBarcodeOrUseNfc