import { useSetRecoilState } from 'recoil'
import { POST_user_chat_sendMessage } from '../_layout/POST_user_chat_sendMessage'
import { chatMessages_atom } from '../messages/chatMessages.atom'
import { GET_chat_chatOptions } from './GET_chat_chatOptions.api'


const useChat_chatOptions = () => {
    const setChatMessages = useSetRecoilState(chatMessages_atom)


    const getChatOptions = async () => {
        const resp = await GET_chat_chatOptions()

        return resp
    } 


    const ask_andAddReply = async (question) => {
        _askQuestionText_withTimeout(question)
        
        const resp = await POST_user_chat_sendMessage(question)
        if (resp.error) {
            alert(resp.error)
            return
        }

        _addReplyText(resp.answer)
    }

    
    const _addReplyText = (answer) => {
        setChatMessages((prev) => [...prev, {text: answer, sender: 'chatbot'}])  
    }

      
    const _askQuestionText_withTimeout = (questionText) => {
        setTimeout(() => {
            setChatMessages((prev) => [...prev, {text: questionText, sender: 'user'}])
        }, 600) //600je tu preto, lebo ked kliknem na option v carte tak potom v chat screen sa znova chat premaze, inak by mi to nezobrazilo
    }
    
    return {
        getChatOptions,
        ask_andAddReply
    }
}

export default useChat_chatOptions