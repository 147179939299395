import { apiProcess } from '../host/apiProcess'
import { createFetchParams } from '../host/createFetchParams'

export const apiPost = async (url, payload) => {
    try {
        let fp = createFetchParams(url, 'POST', '', payload)
        if (fp.error) 
            return {error: fp.error}
        
        const response = await fetch(fp.full_url, fp.options)
        if (!response.ok) 
            return {error: 'didnt respond with json, something is very broken'}

        const data = await apiProcess(response)

        console.log('🐤 apiPost with payload', fp.full_url, payload)
        return data

    } catch (error) {
        return {error: error}
    }
}