import { useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { userInfo_atom } from './userInfo_atom'
import { POST_auth_guest } from './POST_auth_guest'
import { GET_customer_info } from './GET_customer_info'
import { getJwt_LS, setJwt_LS, removeJwt_LS } from './jwt_LS.util'
import { remove_wasVisitedProfilePage_LS } from '~/home/userProfilePrompt/wasVisitedProfilePage_LS.util'


const useUserSession = () => {
    const setUserInfo = useSetRecoilState(userInfo_atom)
    const [isLoaded, set_isLoaded] = useState(false)


    const createGuestAccount = async () => {
        const resp = await POST_auth_guest()
        if (resp.error) {
            alert(resp.error)
            return resp
        }
        setJwt_LS(resp.jwt)

        return resp
    }

    const loadUserInfo = async () => {
        set_isLoaded(false)   
        const resp = await GET_customer_info()
        if (resp.error) {
            if (resp.error == 'jwt expired') {
                removeJwt_LS()
                remove_wasVisitedProfilePage_LS()
                alert('DEV: Jwt expired, please restart app', resp.error)
                return resp
            }
            alert(resp.error) // <--tuto by sa mal triggerovat error alert komponent, momentalne jedine riesenie ako to tu presunut je dat set_showErrorALert do recoilu 
            return resp
        }
        setUserInfo(resp)
        set_isLoaded(true)
        return resp
    }

    const hasJwtSession = () => {
        return getJwt_LS()
    }

    return {
        createGuestAccount,
        loadUserInfo,
        hasJwtSession,
        isLoaded
    }
}

export default useUserSession