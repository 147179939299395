export const getYearAndNameOfMonth = () => {
    const localDate = new Date()

    const monthNumber = localDate.getMonth() + 1
    const year        = localDate.getFullYear()

    let nameOfMonth = ''

    if (monthNumber == 1) {
        nameOfMonth = 'January'
    } else if (monthNumber == 2) {
        nameOfMonth = 'February'
    } else if (monthNumber == 3) {
        nameOfMonth = 'March'
    } else if (monthNumber == 4) {
        nameOfMonth = 'April'
    } else if (monthNumber == 5) {
        nameOfMonth = 'May'
    } else if (monthNumber == 6) {
        nameOfMonth = 'June'
    } else if (monthNumber == 7) {
        nameOfMonth = 'July'
    } else if (monthNumber == 8) {
        nameOfMonth = 'August'
    } else if (monthNumber == 9) {
        nameOfMonth = 'September'
    } else if (monthNumber == 10) {
        nameOfMonth = 'October'
    } else if (monthNumber == 11) {
        nameOfMonth = 'November'
    } else if (monthNumber == 12) {
        nameOfMonth = 'December'
    }

    return `${nameOfMonth} ${year}`
}