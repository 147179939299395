import { IonIcon } from '@ionic/react'
import { alertSharp } from 'ionicons/icons'
import css from './Discount.module.css'


const Discount = () => {
    return (
        <div className={css.discount}>
            <div className={css.icon}>
                <IonIcon icon={alertSharp}/>
            </div> 
            <div>
                2 + 1 Discount
            </div>
        </div>
    )
}

export default Discount