import { GET_product_by_id } from './GET_product_by_id'

const useProductApi = () => {

    const getProductById = async (urlId) => {
        const resp = await GET_product_by_id(urlId)
        return resp  
    }
    
    return {  
        getProductById 
    }
}

export default useProductApi