import { apiGet_auth } from '../apiAuth/apiGet_auth'
import { apiPost_auth } from '../apiAuth/apiPost_auth'
import { apiGet } from '../apiPublic/apiGet'
import { apiPost } from '../apiPublic/apiPost'
import { mockApiAny } from '../mockApi/mockApiAny'

export const api = {
    get: apiGet,
    get_m: (url) => mockApiAny(url, 'GET'),
    post: apiPost,
    post_m: (url, payloadDummy) => mockApiAny(url, 'POST', payloadDummy),

    // Auth
    getAuth: apiGet_auth,
    getAuth_m: (url) => mockApiAny(url, 'GET'),
    postAuth: apiPost_auth,
    postAuth_m: (url, payloadDummy) => mockApiAny(url, 'POST', payloadDummy),
}