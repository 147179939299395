import { useHistory } from 'react-router'
import { IonPage, IonHeader, IonContent, IonButton, IonIcon } from '@ionic/react'
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonImg, IonLabel } from '@ionic/react'
import { addOutline, chevronDownOutline } from 'ionicons/icons'

import { POST_loyaltyCard_create } from './POST_loyaltyCard_create'
import Header from '~/app_layout/header/Header'
import css from './AddLoyaltyCard.module.css'
import odNasichLogo from '../_assets/odnasich_logo_cnv.png'


const AddLoyaltyCard = () => {

    const history = useHistory()

    const addLoyaltyCard = async () => {
        const resp = await POST_loyaltyCard_create()
        if (resp.error) {
            alert(resp.error)
            return
        }
        
        history.goBack()
        // alert(resp.success)
    }

    return (
        <IonPage>
            <IonHeader>
                <Header
                    title={'Add loyalty card'} 
                    // showLocation={true}
                    goBackButton={true}
                    defaultHref='/payment'
                    showUserIcon={true}
                />
            </IonHeader>

            <IonContent className={css.content}>
                {/* <IonButton onClick={addLoyaltyCard}>
                    Add Loyalty Card
                </IonButton> */}
                <div className={css.labels}>
                    <IonLabel className={css.list}>List</IonLabel>
                    <IonLabel className={css.filter}>
                        Last used
                        <IonIcon icon={chevronDownOutline} />
                    </IonLabel>
                </div>
                {/* Nepoznám úplne logiku ale to vyzerá, že sem časom pôjde IonList...*/}
                <IonCard className={css.card}> 
                    <IonCardHeader className={css.cardHeader}>
                        <IonCardTitle>Membership</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className={css.cardContent}>
                        <IonImg
                            className={css.cardLogo}
                            src={odNasichLogo}
                            alt='Brand logo Od Našich'
                        ></IonImg>
                    </IonCardContent>
                </IonCard>

                <IonButton
                    className={`${css.addBtn} secondary`}
                    onClick={addLoyaltyCard}
                >
                    <IonIcon
                        icon={addOutline}
                    />
                    Add your loyalty card
                </IonButton>
            </IonContent>
        </IonPage>
    )
}

export default AddLoyaltyCard