export const formatDate = (fullDate) => {
    try {
        const localDate = new Date(fullDate)

        const day     = localDate.getDate()
        const month   = localDate.getMonth() + 1
        const hours   = localDate.getHours()
        const minutes = localDate.getMinutes()

        const formattedDay     = day
        const formattedMonth   = month     < 10 ? '0' + month   : month
        const formattedHours   = hours     < 10 ? '0' + hours   : hours
        const formattedMinutes = minutes   < 10 ? '0' + minutes : minutes

        return `${formattedDay}/${formattedMonth} ${formattedHours}:${formattedMinutes}`

    } catch (error) {
        console.log(error)
        return 'missing'
    }
}